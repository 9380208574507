/* eslint-disable no-template-curly-in-string */
export default {
  featured: 'Featured',
  popular: 'Beliebt',
  couponsAndDeals: 'Gutscheine und Deals',
  yourFavorites: 'Ihre Favoriten',
  coupons: 'Gutscheine',
  headerCoupons: 'Karma | Gutscheine',
  lastSaved: 'Zuletzt gespeichert',
  favorites: 'Favoriten',
  trending: 'Angesagt',
  weAreLoving: 'Wir lieben',
  karmaHome: 'Karma | Startseite',
  goodMorning: 'Guten Morgen',
  goodAfternoon: 'Guten Tag',
  goodEvening: 'Guten Abend',
  addFavoriteStores: 'Lieblingsgeschäfte hinzufügen',
  nothingFound: 'Nichts gefunden',
  number: '{{value, number}}',
  clearNumber: '{{value, clearNumber}}',
  saveSettings: 'Einstellungen speichern',
  enableBrand: '{{name}} aktivieren',
  chrome: 'Chrome',
  edge: 'Edge',
  firefox: 'Firefox',
  ie: 'Internet Explorer',
  oldEdge: 'Edge',
  opera: 'Opera',
  safari: 'Safari',
  remove: 'Entfernen',
  sponsored: 'Gesponsert',
  seeAll: 'Alle ansehen',
  favorite: 'Favorit',
  onSale: 'Angebot',
  lowInStock: 'Nur wenig auf Lager',
  backInStock: 'Wieder auf Lager',
  coupon: 'Gutschein',
  soldOut: 'Ausverkauft',
  fulfilled: 'Erfüllt',
  deal: 'Deal',
  price: '{{currency}}{{value, number(minimumFractionDigits: 2)}}',
  dayRemaining: 'Noch {{count, number}} Tag',
  dayRemaining_plural: 'Noch {{count, number}} Tage',
  karmaStores: 'KarmaCash-Geschäfte',
  priceLeftToWithdraw: 'Nur noch {{currency}}{{value, number}}, um Ihre Belohnungen zu erhalten.',
  fullPrice: '{{currency}}{{value, number}}.{{fraction, fraction}}',
  fraction: '.{{value, fraction}}',
  pendingCash: 'Ausstehendes Bargeld',
  payouts: 'Auszahlungen',
  connectWithPayPal: 'Mit PayPal verbinden',
  selectCause: 'Guten Zweck wählen',
  dayBetweenPayouts: 'Geld kann {{count, number}} Tag, nachdem der Kauf bestätigt wurde, abgehoben werden.',
  dayBetweenPayouts_plural: 'Geld kann {{count, number}} Tage, nachdem der Kauf bestätigt wurde, abgehoben werden.',
  list: 'Liste:',
  emptyWallet: 'Ihre Wallet ist leer',
  startShoppingToEarn: 'Fangen Sie mit dem Shoppen an, um Belohnungen zu verdienen!',
  withdraw: 'Auszahlen',
  yourDonations: 'Ihre spenden',
  outOfAmount: 'Kein/e {{currency}}{{value, number}}',
  gettingCloser: 'Sie nähern sich dem Ziel!',
  canWithdrawEarnings: 'Sie können jetzt Ihre Verdienste auszahlen lassen.',
  workedAt: 'Bearbeitet {{date}}',
  copied: 'Kopiert!',
  getCoupon: 'Gutschein erhalten',
  shopNow: 'Jetzt shoppen',
  karmaLogo: 'Karma-Logo',
  stores: 'Geschäfte',
  myLists: 'Meine Listen',
  uppercase: '{{text, uppercase}}',
  capitalize: '{{text, capitalize}}',
  all: 'Alle',
  lastWeek: 'Letzte Woche',
  lastMonth: 'Letzter Monat',
  lastThreeMonths: 'Letzte 3 Monate',
  clickID: 'Click-ID:',
  date: 'Datum',
  time: 'Zeit',
  retailer: 'Händler',
  orderID: 'Bestell-ID',
  startShoppingToEarnKarmaCash: 'Jetzt shoppen, um KarmaCash zu verdienen,',
  browseStores: 'Geschäfte durchsuchen',
  doNotHaveClicks: 'Sie haben für diesen Zeitraum keine Klicks.',
  doNotHaveTransactions: 'Sie haben für diesen Zeitraum keine Transaktionen.',
  payPalAlt: 'PayPal',
  payPal: 'PayPal:',
  welcomeBonus: 'Willkommensbonus!',
  karmaGives: 'Karma Gives!',
  karmaGivesRefund: 'Karma Gives-Rückerstattung!',
  completed: 'Fertig',
  notQualified: 'Nicht qualifiziert',
  pending: 'Ausstehend',
  cancelled: 'Storniert',
  paid: 'Bezahlt',
  empty: 'Leer',
  expired: 'Abgelaufen',
  discoverAndShop: 'Entdecken & Shoppen',
  contactUs: 'Kontaktieren Sie uns',
  news: 'Neuigkeiten',
  faq: 'Häufig gestellte Fragen',
  aboutUs: 'Über uns',
  karmaItem: 'Artikel von Karma',
  email: 'E-Mail',
  congrats: 'Glückwunsch!',
  congratulations: 'Glückwunsch!',
  getKarmaButton: 'Karma-Button holen!',
  saveThingsToBuy: 'Speichern Sie Dinge, die Sie später mit Karma kaufen möchten!',
  savedFirstItem: 'Sie haben Ihren ersten Artikel gespeichert!',
  getTheButton: 'Button holen',
  savedFirstItem_plural: 'Sie haben bereits ein paar Artikel gespeichert!',
  iWantToSeeOther: 'Ich möchte andere <1>unterstützte Geschäfte</1> sehen',
  neverMissProducts: 'Verpassen Sie nicht, wenn Produkte als Angebot erhältlich oder wieder auf Lager sind!',
  shareByEmail: 'Per E-Mail teilen',
  whenIFindSmth: 'Wenn ich etwas finde, das ich haben möchte, speichere ich es in Karma!',
  completeSignUp: 'Registrierung abschließen',
  addToList: 'Zur Liste hinzufügen',
  chat: 'Chat',
  karmaCash: 'KarmaCash',
  clickToSaveItem: 'Klicken, um einen Artikel zu speichern',
  createListAndPressEnter: "Liste erstellen und 'Enter' drücken",
  confirmDeleteProduct: 'Sind Sie sicher, dass Sie dieses Produkt löschen möchten?',
  shareOnFacebook: 'Auf Facebook teilen',
  selectAll: 'Alle auswählen',
  selectProp: '{{name}} auswählen',
  delete: 'Löschen',
  fulfill: 'Erfüllen',
  mute: 'Stummschalten',
  anyPriceChange: 'Jegliche Preisänderung',
  priceOff: '{{value, number}} % Rabatt',
  notifyWhen: 'Benachrichtigen, wenn',
  tweetOnTwitter: 'Tweet auf Twitter',
  shareProduct: 'Produkt teilen',
  markAsFulfilled: 'Als Erfüllt markieren',
  unmuteNotifications: 'Benachrichtigungen aktivieren',
  muteNotifications: 'Benachrichtigungen stummschalten',
  priceEvolution: 'Preisentwicklung',
  logOut: 'Abmelden',
  twitter: 'Twitter',
  facebook: 'Facebook',
  instagram: 'Instagram',
  whatsapp: 'WhatsApp',
  messenger: 'Messenger',
  privacyPolicy: 'Datenschutzrichtlinie',
  confirmDelete: 'Sind Sie sicher, dass Sie diese Produkte löschen möchten?',
  termsAndConditions: 'Geschäftsbedingungen',
  logosProvidedBy: 'Logos zur Verfügung gestellt von {{name}}',
  copyright:
    'Copyright © {{year, clearNumber}} Karma Shopping Ltd., handelt unter dem Namen Karma. Alle Rechte vorbehalten.',
  noNotifications: 'Keine Benachrichtigungen',
  clearAllNotifications: 'Alle Benachrichtigungen löschen',
  areYouSureRemoveNotifications: 'Sind Sie sicher, dass Sie alle Benachrichtigungen entfernen möchten?',
  deleteProducts: 'Produkte löschen',
  no: 'Nein',
  yes: 'Ja',
  headerStores: 'Karma | Geschäfte',
  clearNotification: 'Benachrichtigung löschen',
  clear: 'Löschen',
  bell: 'Glocke',
  remindInMinute: 'Mich in {{value, number}} Minute erinnern',
  remindInMinute_plural: 'Mich in {{value, number}} Minuten erinnern',
  remindInHour: 'Mich in {{value, number}} Stunde erinnern',
  remindInHour_plural: 'Mich in {{value, number}} Stunden erinnern',
  retailers: 'Händler',
  claimKarmaCash: 'Bis zu {{percent, number}} % KarmaCash beanspruchen',
  clickOnClaimButton: 'Klicken Sie auf „Bis zu {{percent, number}} % KarmaCash beanspruchen“',
  goShopping: 'Shoppen gehen',
  rackUpCash: 'Verdienen Sie Bargeld',
  getPaidDirectly: 'Direkt bezahlt werden',
  earnRealMoney: 'Erhalten Sie bei buchstäblich jedem Einkauf bares Geld zurück.',
  trackYourEarnings: 'Verfolgen Sie Ihren Verdienst und erhalten Sie jede Saison eine Barzahlung.',
  ordinalNumber: '.{{value, fraction}}',
  relatedStores: 'Zugehörige Geschäfte',
  defaultWhatHappensNext:
    'Um bei Ihrem nächsten Einkauf automatisch KarmaCash zu erhalten, klicken Sie sich zu einem unserer qualifizierten KarmaCash-Händler auf Karma durch, wo Sie wie gewohnt weiter einkaufen! Beachten Sie bitte, dass Sie nur dann KarmaCash verdienen können, wenn Sie den qualifizierten Händler über Karmas App oder Dashboard (einschließlich Push-Benachrichtigungen) besuchen oder wenn Sie vor dem Kauf mit Karmas Browser-Erweiterung auf dem Desktop/Mobilgerät interagieren. Hat der Händler Ihren Einkauf verifiziert, bestätigt Karma Ihren Verdienst in Ihrer Karma-Geldbörse.',
  defaultTermsAndConditions:
    'KarmaCash ist nicht für den Kauf von Geschenkkarten oder den Teil eines Kaufs, der mit einer Geschenkkarte, einem Geschäftsguthaben, Treuepunkten oder einem anderen Punkteprogramm bezahlt wurde. Die Verwendung von Promotion-Codes, die nicht auf der Website von Karma aufgeführt sind, kann KarmaCash ungültig machen. Für bestimmte Produkte oder Kategorien können zusätzliche Einschränkungen gelten.',
  whatHappensNext: 'Was passiert als nächstes?',
  about: 'Über uns',
  readMore: 'Mehr erfahren',
  frequentlyAskedQuestions: 'Häufig gestellte Fragen',
  loadMore: 'Mehr laden',
  questionButton: 'Frage-Button',
  headerKarmaCash: 'Karma | Cash-Belohnungen',
  recommended: 'Empfohlen',
  highToLow: 'Absteigend',
  lowToHigh: 'Aufsteigend',
  sortBy: 'Sortieren nach:',
  exploreStores: 'Geschäfte entdecken',
  weHaveNotFoundStores: 'Wir haben keine Geschäfte gefunden, die Ihrer Anfrage entsprechen',
  weHaveNotFoundCoupons: 'Wir haben keine Gutscheine gefunden, die Ihrer Anfrage entsprechen',
  headerApps: 'Karma | Apps',
  karmaApps: 'Gutes Karma, überall.',
  stayUpdated:
    'Bleiben Sie auf all Ihren Geräten auf dem Laufenden. Mit unserem digitalen Shopping-Assistenten können Sie Artikel aus mehr als {{number, number}}k Geschäften speichern.',
  getFaster: 'Laden Sie Karma auf Ihren Mac für ein schnelleres und reibungsloseres Erlebnis herunter.',
  headerProfile: 'Karma | Profil',
  headerWallet: 'Karma | Wallet',
  totalBalance: 'Gesamtes Guthaben',
  editYourAccount: 'Ihr Konto bearbeiten',
  emailAddress: 'E-Mail-Adresse',
  enterEmailAddress: 'E-Mail-Adresse eingeben',
  enterEmail: 'E-Mail eingeben',
  enterEmailLower: 'E-Mail eingeben',
  phoneNumber: 'Telefonnummer',
  enterPhoneNumber: 'Telefonnummer eingeben',
  fullName: 'Vollständiger Name',
  enterFullName: 'Vollständigen Namen eingeben',
  enterPlaceholder: '{{name}} eingeben',
  birthday: 'Geburtstag',
  year: 'Jahr',
  month: 'Monat',
  day: 'Tag',
  gender: 'Geschlecht',
  language: 'Sprache',
  currency: 'Währung',
  multiCurrency: 'Mehrere Währungen',
  male: 'Männlich',
  female: 'Weiblich',
  notWantShare: 'Ich möchte nicht teilen',
  mobilePush: 'Mobile Push',
  webPush: 'Web Push',
  disconnectFromPayPal: 'Von PayPal trennen',
  loginAsUser: 'FUT',
  selectYearAndMonth: 'Bitte zuerst Jahr und Monat auswählen',
  notSureWhereToStart: 'Nicht sicher, wo Sie anfangen sollen?',
  youDidntSaveItems: 'Sie haben noch keine Artikel gespeichert.',
  searchItems: 'Artikel suchen',
  headerDashboard: 'Karma | Dashboard',
  headerSales: 'Karma | Angebote',
  couldNotFindStores: 'Ups, wir konnten keine Angebote finden',
  karmaGivesValue: '{{value, number}} $',
  karmaGivesModalTitle: 'Hier sind {{value, number}} $ GRATIS, damit Sie loslegen können',
  hereIsABitOfGoodKarma: 'Hier ist etwas gutes Karma für die Registrierung bei uns!',
  collectAndStartSaving: 'Sammeln und mit dem Sparen beginnen',
  termsAndConditionsAppersant: 'Allgemeine Geschäftsbedingungen',
  mayApply: 'können gelten',
  termsAndConditionsError:
    'Bitte stimmen Sie zuerst unseren Geschäftsbedingungen und unserer Datenschutzrichtlinie zu.',
  validEmailError: 'Bitte geben Sie eine gültige E-Mail ein.',
  completeSignUpCapital: 'Registrierung abschließen',
  or: 'ODER',
  password: 'Passwort',
  joinUs: 'Registrieren',
  reviewsPlus: 'Über {{amount, number}}k Bewertungen',
  logIn: 'Anmelden',
  termsAgreement:
    'Wenn Sie sich registrieren, stimmen Sie unseren <1>Geschäftsbedingungen</1> und unserer <3>Datenschutzrichtlinie</3> zu',
  termsAgreementWithCheckbox:
    'Ich stimme den <1>Allgemeinen Geschäftsbedingungen</1> und der <3>Datenschutzrichtlinie</3> zu',
  createANewList: 'Neue Liste erstellen',
  listCreateBlickError: 'Liste kann nicht leer sein',
  enterListName: 'Name für Liste eingeben',
  listDescriptionOptional: 'Beschreibung der Liste (Optional)',
  listName: 'Name für Liste',
  enterDescriptionForList: 'Geben Sie eine Beschreibung für Ihre Liste ein',
  save: 'Speichern',
  deleteList: 'Liste löschen',
  areYouSureRemoveList: 'Sind Sie sicher, dass Sie diese Liste entfernen möchten?',
  editList: 'Liste bearbeiten',
  listNameCanNotBeBlanck: 'Liste kann nicht leer sein',
  shareList: 'Liste teilen',
  notAMemberCreateAccount: 'Kein Mitglied? <1>Konto erstellen!</1>',
  backToLogin: 'Zurück zum Login',
  enterYourEmail: 'E-Mail-Adresse eingeben',
  resetPassword: 'Passwort zurücksetzen',
  forgotPasswordMessage:
    'Sie erhalten in wenigen Minuten eine E-Mail mit Anweisungen, wie Sie Ihr Passwort zurücksetzen können.',
  forgotPassword: 'Passwort vergessen?',
  reCaptchaMessage:
    'Diese Website wird von reCAPTCHA geschützt und die <1>Datenschutzrichtlinie</1> und <3>Servicebedingungen</3> von Google gelten',
  pleaseScan: 'Bitte scannen',
  saveAnItem: 'Einen Artikel speichern',
  areYouSureFulfilled: 'Sind Sie sicher, dass Sie diese Produkte als Erfüllt markieren möchten?',
  muteTagDesc: 'Sind Sie sicher, dass Sie die Benachrichtigungen für diese Produkte stummschalten möchten?',
  muteTagTitle: 'Benachrichtigungen stummschalten',
  ok: 'OK',
  canAccessEarningsInPayPal: 'Sie können auf Ihre Einnahmen in Ihrem PayPal-Konto zugreifen',
  ccpaTitle: 'California Consumer Privacy Act (CCPA), geändert durch den California Privacy Rights Act (CPRA)',
  ccpaText:
    ' Indem ich dieses Formular abschicke, mache ich von meinem Recht Gebrauch, dem Verkauf meiner persönlichen Daten zu widersprechen.',
  submit: 'Senden',
  joinKarmaAndGetRewards: 'Bei Karma registrieren und {{stake, number}} Belohnungen für {{name}} erhalten',
  doNotMissOut: 'Nicht verpassen. Vielleicht können Sie bei Ihrem Artikel Geld sparen!',
  signUpAndGetMoney: 'Registrieren und {{value, number}} $ erhalten',
  itemAlert: 'Sie haben einen Artikelalarm!',
  accessKarmaCash: 'Auf Ihr KarmaCash zugreifen',
  youEarnedKarmaCash: 'Sie haben gerade KarmaCash verdient!',
  verifyYourPaypalAccount: 'Verifizieren Sie Ihr Paypal<1/>-Konto',
  weHaveSentAnEmailTo: 'Wir haben eine E-Mail gesendet an',
  'Fall Favorites': 'Herbst-Favoriten',
  'Karma Cash': 'KarmaCash',
  'Clothing & Luxury': 'Kleidung & Luxus',
  'Home Decor & Furniture': 'Heimdekoration & Möbel',
  'Electronics & Office Supplies': 'Elektronik & Bürobedarf',
  Luxury: 'Luxus',
  Clothing: 'Kleidung ',
  'Handbags & Shoes': 'Handtaschen & Schuhe',
  'Jewelry & Watches': 'Schmuck & Uhren',
  Accessories: 'Accessoires',
  'Beauty Essentials': 'Beauty Essentials',
  'Health & Wellness': 'Gesundheit & Wellness',
  Lifestyle: 'Lifestyle',
  'Sports & Outdoors': 'Sport & Natur',
  Travel: 'Reisen',
  'Electronics & Office supplies': 'Elektronik & Bürobedarf',
  'Entertainment & Online Services': 'Entertainment & Online-Services',
  Education: 'Bildung',
  'Finance & FinTech': 'Finanzen & FinTech',
  'Pet Supplies': 'Haustierbedarf',
  'Food & Beverages': 'Essen & Getränke',
  Men: 'Männer',
  Women: 'Frauen',
  Unisex: 'Unisex',
  Kids: 'Kinder',
  Baby: 'Baby',
  notifications: 'Benachrichtigungen',
  changesSaved: 'Änderungen gespeichert!',
  headerRetailer: 'Karma | Händler',
  headerRedirect: 'Karma | Weiterleiten ...',
  karma: 'Karma',
  goBackToKarma: 'Zurück zu Karma',
  authentication: 'Authentifizierung',
  authSuccess: 'Glückwunsch! Sie haben sich erfolgreich eingeloggt.',
  authFailure: 'Ups, wir konnten Sie nicht authentifizieren. Versuchen Sie es später erneut.',
  mayNotQualify: 'Einige Produkte qualifizieren sich möglicherweise nicht für KarmaCash',
  checkOutRetailers: 'Sehen Sie sich unsere unterstützten KarmaCash-Händler an.',
  headerHowItWorks: 'Karma | So funktioniert es',
  accept: 'Akzeptieren',
  cookies:
    'Karma verwendet Cookies, um sicherzustellen, dass Sie die Dinge sehen, die Ihnen gefallen. <1>Klicken Sie hier</1>, um unsere vollständige Richtlinie zu lesen.',
  headerError: 'Karma | Fehler',
  pageNotFound: 'Seite nicht gefunden',
  pageDoesNotExist: 'Ups, es sieht so aus, als ob diese Seite nicht existiert.',
  backHome: 'Zurück zur Startseite',
  startShopping: 'Mit dem Shoppen beginnen',
  illustration: 'Illustration',
  dashboard: 'Dashboard',
  allRightsReserved: 'Alle Rechte vorbehalten.',
  product: 'Produkt',
  disclaimer:
    'Karma Shopping Ltd. handelt unter dem Namen Karma. Karma ist nicht Eigentümer von Marken Dritter und behauptet auch nicht, dies zu sein. Marken Dritter, die über die Karma-Plattform sichtbar oder direkt oder indirekt zugänglich sind, stehen nicht notwendigerweise in einer Beziehung zu Karma oder mit Karma verbundenen Dritten oder Dienstleistern von Karma.',
  help: 'Hilfe',
  team: 'Team',
  howItWorks: 'So funktioniert es',
  supportedStores: 'Unterstützte Geschäfte',
  company: 'Unternehmen',
  planYourPurchase: 'Planen Sie Ihren nächsten Einkauf mit unserer kostenlosen App!',
  itSitsInBrowser: 'Er wird in Ihrem Webbrowser angezeigt, während Sie in Ihren Lieblingsgeschäften stöbern.',
  seeSmthYouLike:
    'Sehen Sie etwas, das Ihnen gefällt? Klicken Sie auf den Button, um es auf Ihrer Liste zu speichern und Karma wird den Preis und die Verfügbarkeit für Sie im Auge behalten.',
  youWillGetNotified:
    'Sie werden sofort benachrichtigt, wenn einer Ihrer gespeicherten Artikel als Angebot erhältlich ist, im Preis sinkt oder in der von Ihnen gewünschten Größe und Farbe wieder auf Lager ist.',
  everythingYouWant:
    'Alles, was Sie kaufen möchten, befindet sich an einem einzigen Ort, so dass Sie den Überblick behalten und nicht mehr mit mehreren Wunschlisten jonglieren müssen.',
  headerContactUs: 'Karma | Kontaktieren Sie uns',
  needToGetInTouch: 'Möchten Sie Kontakt aufnehmen?',
  dropALine: 'Schreiben Sie uns',
  comeHangOut: 'Schauen Sie vorbei',
  joinOurFacebookCommunity: 'Treten Sie unserer Facebook-Community bei',
  followOnInstagram: 'Folgen Sie uns auf Instagram',
  headerAboutUs: 'Karma | Über uns',
  addTheItems:
    'Fügen Sie die gewünschten Artikel hinzu - Sie können sie bis hin zu Größe und Farbe anpassen - und die App benachrichtigt Sie, wenn sie im Angebot sind.',
  jonathanSay:
    'Jonathan: Während Einzelhändler schon seit Jahren die KI nutzen, ist es nun der Verbraucher, der maschinelles Lernen und Automatisierungswerkzeuge für mehr Effizienz nutzen wird.',
  justDownload:
    'Laden Sie einfach einen "Button" herunter und klicken Sie darauf, wenn Sie auf einen Artikel stoßen, der Ihnen gefällt, um ihn in Ihrer Liste zu speichern. Auf dem Handy können Sie Artikel direkt auf denselben Listen "teilen".',
  ifYouFind:
    'Wenn Sie online einen Artikel finden, der Ihnen gefällt, aber Ihr Budget übersteigt, können Sie einen Angebotsalarm einrichten, damit Sie benachrichtigt werden, wenn der Preis fällt.',
  onlyAHandful:
    'Es gibt nur noch eine Handvoll Unternehmen, die Angebotsalarme für Online-Mitglieder anbieten, darunter Karma.',
  alwaysBuy: 'Kaufen Sie mit Karma immer etwas im Angebot.',
  headerCareers: 'Karma | Karriere',
  getInTouch: 'Melden Sie sich bei uns:',
  shop: 'Shopping',
  weCouldNotFindResults: "Wir konnten für '{{name}}' keine Ergebnisse finden",
  couldNotFindResults: 'Wir konnten keine Ergebnisse finden',
  shopAllStores: 'In allen unterstützten Geschäften shoppen',
  popularStores: 'Beliebte Geschäfte',
  shops: 'Shops',
  headerDiscoverAndShop: 'Karma | Entdecken und shoppen Sie die neuesten gesparten Artikel',
  prev: 'Vorh.',
  next: 'Nächstes',
  headerDemo: 'Karma | Demo',
  getTheKarmaButton: 'Karma-Button holen',
  installedKarma: 'Sie haben Karma erfolgreich installiert!',
  settingUpAccount: 'Lassen Sie uns jetzt Ihr Konto einrichten',
  signUpNow: 'Jetzt registrieren',
  logInNow: 'Jetzt einloggen',
  signUpLater: 'Später registrieren',
  wePartnerWith: 'Wir arbeiten mit über {{number, number}}k Online-Geschäfte zusammen!',
  pickStoresToSee: 'Wählen Sie unten ein Geschäft aus, um zu sehen, wie es funktioniert:',
  leaveKarma:
    'Beachten Sie, dass Sie Karma verlassen und auf die Shop-Seite gehen müssen, um das volle Erlebnis zu haben.',
  searchFavoriteStores: 'Durchsuchen Sie Ihre Lieblingsgeschäfte',
  clickBelowToStart: 'Unten klicken, um anzufangen',
  chooseOneOfTheOptions: 'Wählen Sie unten eine Option aus',
  clickToBegin: 'Klicken Sie in die Suchleiste, um zu beginnen',
  preferNotToSay: 'Ich möchte das nicht sagen',
  done: 'Fertig',
  headerUnsubscribe: 'Karma | Abbestellen',
  changeMind: 'Meinung geändert?',
  resubscribeList: 'Melden Sie sich bei Ihrer Mailing-Liste wieder an.',
  resubscribeList_plural: 'Melden Sie sich bei Ihrer Mailing-Liste hier wieder an.',
  changePreferencesAtAnyTime: 'Sie können Ihre Präferenzen auch jederzeit über Ihre<1>Kontoeinstellungen ändern.</1>',
  preferencesUpdated: 'Wir haben Ihre E-Mail-Präferenzen aktualisiert',
  sorryToSeeYouGo: 'Wir bedauern, dass Sie gehen!',
  reSubscribed: 'Sie haben sich wieder angemeldet',
  allowHours: 'Es kann bis zu {{hours, number}} Stunden dauern, bis die Änderungen wirksam werden.',
  missWhenYouGone:
    'Wir werden Sie vermissen, wenn Sie gehen! Es kann bis zu {{hours, number}} Stunden dauern, bis die Änderungen wirksam werden.',
  headerDisablingSubscribe: 'Karma | Abonnement deaktivieren',
  noLongerAlerts: 'Sie erhalten für diesen Artikel keine Alarme mehr.',
  turnAlertsBack: 'Alarme wieder einschalten.',
  moveAnimations: 'Button-verschieben-Animation',
  headerExtensionDeleted: 'Karma | Erweiterung gelöscht',
  areSorryToSeeYouGo: 'Es tut uns leid, dass Sie gehen!',
  youHaveGotInYourWallet: 'Sie haben {{value}} $ in Ihrer Wallet.',
  toWithdraw: ' um Ihre Verdienste auszahlen zu lassen!',
  toContinue: ' um weither Belohnungen zu verdienen!',
  reasonUninstalled: 'Bitte teilen Sie uns mit, warum Sie deinstalliert haben:',
  getInTouchBtn: 'Kontaktieren Sie uns',
  howWeCanIncrease: 'So können Sie durch Werbung mit Karma Ihre Bekanntheit steigern und Ihren Umsatz erhöhen',
  helpingShoppers:
    'Eine unserer wichtigsten Prioritäten als persönlicher Shopping-Assistent ist es, den Kunden dabei zu helfen, Marken zu entdecken, die sie über Jahre hinweg lieben werden.',
  whyWeOfferBrands:
    'Deshalb bieten wir Marken, die sich durch eine hohe Kundenzufriedenheit auszeichnen, mehrere Möglichkeiten, mit unserem Publikum in Kontakt zu treten. Sie können Ihre Kampagnenziele erreichen und unsere Zielgruppe lernt ihre neue Lieblingsmarke kennen.',
  winWin:
    'Wir bieten Marken, die eine konstant hohe Kundenzufriedenheit aufweisen, mehrere Möglichkeiten, mit unserem Publikum in Kontakt zu treten. Sie erreichen Ihre Kampagnenziele und unser Publikum lernt seine neue Lieblingsmarke kennen - eine echte Win-Win-Situation!',
  getFeaturedRight: 'Werden Sie direkt im Dashboard unserer App und Browsererweiterung vorgestellt',
  getFeaturedDashboard: 'Werden Sie direkt im Karma-Dashboard vorgestellt',
  putYourHand:
    'Bringen Sie Ihre Marke, Ihre Produkte oder Ihre Werbeaktionen genau dort unter, wo unsere Nutzer auftauchen, wenn sie zum Kauf bereit sind. Wir können eine Vielzahl von benutzerdefinierten Inhalten erstellen, die den Traffic dorthin lenken, wo Sie ihn brauchen.',
  exactPlace: 'Platzieren Sie Ihre Marke genau dort, wo unsere Nutzer auftauchen, wenn sie zum Kauf bereit sind.',
  dedicatedInAppPlacement: 'Spezielle In-App-Platzierung',
  dashboardFeaturedBanners: 'Dashboard-Banner',
  dashboardPopUpMessages: 'Popup-Nachrichten im Dashboard',
  customEmailsAndNewsLetters:
    'Individuelle E-Mails und Newsletter-Platzierung an über {{subscribers, number}} Abonnenten',
  customEmails: 'Individuelle E-Mails an über {{subscribers, number}} Abonnenten',
  getMoreEyes:
    'Machen Sie mehr Kunden auf Ihr neuestes Angebot, Ihren Gutscheincode oder Ihre Produktlinie aufmerksam, indem Sie eine spezielle E-Mail oder einen Newsletter an die Kunden versenden.',
  putYourBrand: 'Platzieren Sie Ihre Marke genau dort, wo unsere Nutzer auftauchen, wenn sie zum Kauf bereit sind.',
  newsletterInclusion: 'Aufnahme in den Newsletter',
  dedicatedCustomEmails: '{{percent, number}} % spezielle individuelle E-Mails',
  bannerPlacementInEmails: 'Bannerplatzierung in Benachrichtigungs-E-Mails',
  pushNotificationsToShoppers: 'Push-Benachrichtigungen an über {{shoppers, number}} Kunden',
  pushNotificationsGiveTouchPoint:
    'Push-Benachrichtigungen geben Ihrer Kampagne einen weiteren Berührungspunkt und bieten hohe Öffnungs- und Klickraten.',
  featureBrandClients: 'Ausgewählte Markenkunden',
  growingPerMonth: 'Wachstum von +{{number, number}}k pro Monat',
  members: 'Mitglieder',
  percent: '{{value, number}} %',
  kindOfReachYouExpect: 'Welche Art von Reichweite können Sie erwarten?',
  ourAudience: 'Unser Publikum',
  tabIntoNewSource: 'Erschließen Sie eine neue Quelle qualifizierter Kunden für Ihre nächste Kampagne',
  tellUsAboutYourCompany:
    'Erzählen Sie uns ein wenig über Ihr Unternehmen und Ihre Ziele, und wir zeigen Ihnen, wie wir Ihnen helfen können, diese zu übertreffen.',
  openAndTap:
    'Öffnen Sie Safari, gehen Sie zu der Produktseite, die Sie speichern möchten, und tippen Sie auf den Button "Teilen".',
  shareIllustration: 'Illustration teilen',
  tapMore: "Tippen Sie auf den Button 'Mehr' auf der rechten Panelseite.",
  moreIllustration: 'Mehr Illustrationen',
  enableIllustration: 'Illustration aktivieren',
  tapEdit: 'Tippen sie auf den ‘Bearbeiten’-Button im oberen Panel.',
  enableApp:
    "Aktivieren Sie Karma und tippen Sie auf den grünen Button 'Hinzufügen', um es Ihren Favoriten hinzuzufügen. Tippen Sie dann auf 'Fertig'.",
  goBackToTheProduct:
    "Gehen Sie zurück zur Produktseite, tippen Sie erneut auf den Button 'Teilen' und dann auf den Button 'Karma', um Ihr Produkt zu speichern.",
  recommendedBy: 'Empfohlen von',
  ourMembersSavingMoney: 'Unsere Mitglieder ❤️ sparen mit Karma Geld. Das ist der Grund.',
  addToBrowser: 'Zu {{browser}} hinzufügen - Es ist gratis',
  planPurchases: 'Planen Sie ihren nächsten Einkauf',
  karmaMakesItEasy: 'Karma macht es einfach, Ihre nächsten Einkäufe an einem Ort zu organisieren.',
  buyAtRightTime: 'Zum richtigen Zeitpunkt kaufen',
  automaticallyAlert: 'Karma warnt Sie automatisch, wenn der richtige Zeitpunkt zum Kauf gekommen ist.',
  cashOnVacation: 'KarmaCash für Ihren nächsten Urlaub',
  karmaPartners: 'Karma arbeitet mit vielen Reise- und Hotelseiten zusammen.',
  headerRewards: 'Karma | Die besten Gutscheine und Cash-Belohnungen',
  mission: 'Unsere Mission',
  empowerYou: 'Wir helfen Ihnen, beim Online-Shopping die richtigen Entscheidungen zur richtigen Zeit zu treffen.',
  weAreSeriousAboutPrivacy: 'Wir nehmen Ihre Privatsphäre und Sicherheit ernst',
  weDoNotSellData: 'Wir verkaufen Ihre Daten nicht',
  karmaIsSerious: 'Karma nimmt den Schutz Ihrer Daten sehr ernst und wird sie niemals an Dritte verkaufen. Niemals.',
  karmaIsSecure: 'Karma ist sicher',
  weUseBankSecurity:
    'Wir verwenden Sicherheit auf Bankniveau ({{bit, number}}-Bit-Verschlüsselung), um zu gewährleisten, dass Ihre Daten sicher und geschützt sind.',
  helpsSaveMore: 'Karma hilft Ihnen mehr zu sparen!',
  alertMe: 'Mich benachrichtigen',
  buyWithKarmaEarn: 'Mit Karma kaufen - {{number, number}} $ verdienen',
  productSaved:
    'Glückwunsch! Dieses Produkt wurde auf Ihrer Artikelliste gespeichert. Sie werden benachrichtigt, wenn der Preis fällt.',
  willApplyTheBestCoupons: 'Karma wendet die besten Gutscheine automatisch auf Ihren Einkauf an!',
  downloadAndAutoApply: 'Karma herunterladen & automatisch anwenden',
  whatIsKarma: 'Was ist Karma?',
  claimUpToRewards: 'Beanspruchen Sie bis zu {{number, number}} % Cash-Belohnungen',
  youMightAlsoLike: 'Ihnen könnte auch gefallen',
  weAreSorry: 'Es tut uns leid, aber es ist ein Fehler aufgetreten und die Seite kann nicht angezeigt werden.',
  tryToReloadPage: 'Bitte versuchen Sie die Seite neu zu laden.',
  Color: 'Farbe',
  Size: 'Größe',
  latestCoupons: '{{name}} | Karmas neueste Gutscheine & Cashback {{year, clearNumber}}',
  neverPayFullPrice: 'Zahlen Sie nie den vollen Preis. Holen Sie sich {{name}}-Gutscheine & Cashback von Karma',
  neverPayFullPriceForTag: 'Zahlen Sie nie den vollen Preis für {{name}}',
  saveMoneyWhenShoppingAt: 'Sparen Sie Geld, wenn Sie bei {{name}} shoppen. Registrieren Sie sich bei Karma kostenlos',
  saveMoneyWhenShoppingFor: 'Sparen Sie Geld, wenn Sie für {{name}} shoppen. Registrieren Sie sich bei Karma kostenlos',
  tagByBrand: 'Karma | {{tag}} von {{brand}}',
  typeToSearch: 'Zum Suchen tippen',
  getUSDWhenJoinToday: 'Erhalten Sie {{value, number}} $, wenn Sie sich heute bei Karma registrieren!',
  buyProduct: 'Produkt kaufen',
  userWishList: 'Karma | {{name}}-Wunschliste',
  byWhom: 'von',
  copiedToClipboard: 'In die Zwischenablage kopiert!!',
  change: 'Ändern',
  send: 'Senden',
  cause: 'Zweck wählen:',
  aCause: '1 Zweck wählen:',
  close: 'Schließen',
  selectThisCause: 'Diesen Zweck wählen',
  habitatForHumanity:
    '{{name}} möchte die Armut durch Bereitstellung bezahlbarer Sozialwohnungen langfristig bekämpfen. Das Ziel ist die Schaffung einer sicheren Umgebung, in der Gemeinschaften gedeihen können',
  casaDescription:
    'Die National CASA/GAL Association unterstützt den Einsatz gerichtlich ernannter ehrenamtlicher Fürsprecher, damit misshandelte Kinder ein dauerhaftes, sicheres Zuhause mit der Möglichkeit der freien Entfaltung erhalten',
  endHomelessnessDescription:
    'Die National Alliance für {{name}} ist eine überparteiliche Organisation, die sich für die Obdachlosigkeitsbekämpfung in den USA einsetzt',
  pathDescription: 'PATH bereitet der Obdachlosigkeit von Einzelnen, Familien und Gemeinschaften ein Ende',
  feedingAmericaDescription: 'Feeding America ist die größte nationale Hungerhilfeorganisation des Landes',
  mealsOnWheelsDescription:
    '{{name}} befähigt lokale Gemeindeprogramme zur Verbesserung der Gesundheit und Lebensqualität von Senioren, damit keiner hungrig oder isoliert zurückbleibt',
  actionAgainstHunger:
    '{{name}} ist eine globale humanitäre Organisation, die entschlossen gegen die Ursachen und Auswirkungen von Hunger vorgeht',
  supportMedicalAdvancement: 'Förderung des medizinischen Fortschritts',
  saveChildsHeartDescription:
    "„Save A Child's Heart“ spendet unabhängig von Herkunft, Glaube, Geschlecht oder Vermögenslage Trost",
  su2c: '„Stand Up To Cancer“ (SU2C) finanziert und entwickelt neue und aussichtsvolle Krebsbehandlungen, um Patienten heute zu helfen',
  drWithoutBorders:
    '{{name}} ist eine unabhängige, globale Bewegung, die an den bedürftigsten Orten medizinische Hilfe leistet',
  activeMinds:
    '{{name}} ist eine landesweit führende NPO, die sich für die psychische Gesundheit und Bildung junger Erwachsener einsetzt',
  cleanAirTaskForceDesc:
    '{{name}} fördert Änderungen von Technologien und Richtlinien, um kostengünstig eine emissionsfreie, energiereiche Umwelt zu erzielen',
  wwfDesc:
    '{{name}} verfolgt das Ziel, die Natur zu erhalten und die größten Bedrohungen der natürlichen Vielfalt zu reduzieren',
  waterkeepsDesc:
    '{{name}} setzt sich für die Wassersauberkeit ein. Waterkeeper Alliance will sicherstellen, dass weltweit saubere Gewässer verfügbar sind',
  xprice:
    'XPrize arbeitet täglich an der Erschaffung einer besseren Welt, in der jeder Zugang zu sauberem Wasser, nahrhaften Lebensmitteln, günstigem Wohnraum, Lernmöglichkeiten, bester ärztlicher Versorgung und umweltfreundlicher Energie hat',
  globalActDesc: '{{name}} will die Ausmerzung sexueller und wirtschaftlicher Ausbeutung erzielen',
  pcaa: '{{name}} ist bestrebt, unsere Vision einer Welt umzusetzen, in der alle Kinder glücklich und gesund in förderlichen Familien und Gemeinschaften aufwachsen',
  endCAN:
    'EndCAN sensibilisiert für die Auswirkungen von Missbrauch auf die Gesundheit und Psyche mit dem Ziel, Kindesmissbrauch zu beenden',
  futuresDesc:
    'FUTURES bietet bahnbrechende Programme, Richtlinien und Kampagnen, die sowohl Einzelne als auch Organisationen befähigen, Gewalt gegen Frauen und Kinder weltweit zu beenden',
  thornDesc: '{{name} entwickelt Technologien für den Schutz von Kindern vor sexuellem Missbrauch.',
  'Eradicate Homelessness': 'Obdachlosigkeit ausmerzen',
  'Help End Hunger': 'Hunger beenden',
  'Support Medical Advancement': 'Medizinischen Fortschritt fördern',
  'Combat Climate Change': 'Klimawandel bekämpfen',
  'Act To End Abuse': 'Missbrauch beenden',
  itemsYouSaved: 'Günstiger erhaltene Artikel',
  beta: 'BETA',
  alsoAvailableAt: 'Auch erhältlich bei',
  atName: 'bei {{name}}',
  bestPrice: 'Bester Preis',
  passwordWasSuccessfullyReset: 'Passwort erfolgreich zurückgesetzt',
  paidWith: 'Bezahlt mit',
  infoFirstNumber: '{{number, number}}{{unit}}+',
  infoFirstTitle: 'Mitglieder',
  infoSecondNumber: '{{number, number}}{{unit}}+',
  infoSecondTitle: 'Monatl. Besucher',
  infoThirdNumber: '{{number, number}}{{unit}}+',
  infoThirdTitle: 'E-Mail-Liste',
  infoFourthNumber: '{{value, number}}%',
  infoFourthTitle: 'Millenials',
  infoFifthNumber: '{{value, number}}%',
  infoFifthTitle: 'Weiblich',
  infoSixthNumber: '{{value, number}}%',
  infoSixthTitle: 'Aus USA',
  infoSeventhNumber: '{{number, number}}{{unit}}+',
  infoSeventhTitle: 'Abo mobile Push-Nachrichten',
  infoEighthNumber: '{{number, number}}{{unit}}+',
  infoNinthPreNumber: '',
  infoNinthNumber: '${{number, number}}{{unit}}+',
  infoNinthTitle: 'Von Benutzern auszugeben bis Ende {{year, clearNumber}}',
  karmaIsAlwaysWithYou: 'Karma ist bei Ihrem Einkauf immer dabei',
  saveItemsToBuy: 'Artikel speichern und benachrichtigen lassen',
  weDoTheWork: 'Wir finden für Sie die besten Coupons',
  earnRealCashThatAdds: 'Echtes Geld für Ihr Wallet verdienen ',
  setUpStrongPassword: 'Starkes Passwort wählen',
  settingEmailAccount:
    'Mit Karma Geld sparen – letzter Schritt: Anmelden, damit wir Ihnen wichtige Infos zu Ihren Artikeln (z. B. Rabatte) senden können.',
  settingPasswordAccount:
    'Das Passwort muss mindestens {{number, number}} Zeichen enthalten und darf nicht Ihre E-Mail-Anmeldedaten enthalten.',
  countWaysToUse: '{{number, number}} Möglichkeiten, Karma zu verwenden',
  goBack: 'Zurück',
  pleaseChooseStrongerPassword: 'Bitte ein stärkeres Passwort wählen',
  pleaseEnterValidEmailAddress: 'Bitte eine gültige E-Mail-Adresse eingeben',
  illSignUpLater: 'Später anmelden',
  enterYourEmailAddress:
    'Bitte geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen einen Link zum Zurücksetzen Ihres Passworts.',
  headerOnboardingIosExtension: 'Karma | IOS-Erweiterung-Onboarding',
  weWillAutomaticallyApplyCouponsWhereverYouShop: 'Gutscheine automatisch überall anwenden, wo Sie einkaufen',
  continue: 'Weiter',
  changeLanguage: 'Sprache ändern',
  selectedLanguage: 'Ausgewählte Sprache',
  headerMuteItem: 'Karma | Artikel',
  itemHasBeenMuted: 'Erledigt! Dieser Artikel wurde stummgeschaltet.',
  toUnmuteItem: 'Zum Aufheben der Stummschaltung dieses Artikels:',
  headToMyItems: 'Gehen Sie zur Registerkarte ‚Meine Artikel‘.',
  clickThreeButtonsInItem: 'Klicken Sie auf die drei Schaltflächen in der rechten unteren Ecke des Artikels.',
  clickUnmute: 'Klicken Sie auf ‚Stummschaltung aufheben‘.',
  weveJustRaisedInFunding:
    'Wir haben gerade {{value, number}} Mio. $ in einer Serie-A-Finanzierung erhalten, damit Sie intelligenter einkaufen können!',
  weveJustRaised: 'Wir haben gerade {{value, number}} Mio. $ erhalten',
  landingReview1: 'Tolle App! Ich habe einen Preissenkungsalarm erhalten und eine Menge Geld gespart!',
  landingReview2: 'Ich habe bei meinem ersten Kauf {{value, number}} $ gespart. Sehr beeindruckt!',
  landingReview3: 'Es ist eine nützliche App; ich liebe die Benachrichtigungen und Belohnungen.',
  landingReview4: 'Ich habe noch nie ein so reibungsloses Einkaufserlebnis erlebt!',
  landingReview5: 'Ich habe {{value, number}} % gespart! Ich hätte sonst nicht von dem Sale erfahren!',
  landingReview6: 'Einfache Nachverfolgung der Produkte, die Sie lieben ... Sehr zu empfehlen ...!',
  landingReviewAuthor1: 'Justin Nguyen',
  landingReviewAuthor2: 'Amy Ellis',
  landingReviewAuthor3: 'Maude Aethelredd',
  landingReviewAuthor4: 'Patrick Slocum',
  landingReviewAuthor5: 'Michelle Findley',
  landingReviewAuthor6: 'Varsha Hulekal',
  auxiliaryDate: '{{date}}',
  resetMyPassword: 'Mein Passwort zurücksetzen',
  repeatPassword: 'Mein Passwort wiederholen',
  continueAsGuest: 'Als Gast fortfahren',
  viewAt: 'View at {{- name}}',
  signUp: 'Anmelden',
  youMayAlsoLike: 'Das könnte Sie auch interessieren',
  buyOnShop: 'Kaufen auf',
  viewOn: 'Gesehen auf {{- name}}',
  quickView: 'Schnellansicht',
  newList: 'Neue Liste',
  undo: 'Rückgängig machen',
  productDeleted: 'Produkt gelöscht',
  linkCopiedToClipboard: 'Link in die Zwischenablage kopiert',
  wishlists: 'Wunschliste',
  headerPartnerships: 'Karma | Partnerschaft',
  letsJoinForces: 'Lassen Sie uns unsere Kräfte bündeln',
  bePartOfTheSmartShoppingRevolution:
    'Nehmen Sie an der Revolution des intelligenten Einkaufs teil. Wir bringen Ihre Marke vor über {{number, number}} Millionen Käufer, die bereit sind, zur Kasse zu gehen.',
  seeKarmaInAction: 'Sehen Sie Karma in Aktion',
  discoverHowKarma:
    'Entdecken Sie, wie die KI- und Automatisierungslösungen von Karma die gesamte Einkaufsreise verbessern und gezielten Traffic auf Ihre Angebote lenken.',
  gainInsightInto: 'Gewinnen Sie mit jedem gespeicherten Artikel Einblicke in die Kaufabsicht',
  showcaseYourCoupons: 'Präsentieren Sie Ihre Gutscheine in unserem Deal-Finder',
  keepShoppersUpToDate: 'Halten Sie Käufer mit automatischen Benachrichtigungen auf dem Laufenden',
  stepUpYourSales: 'Steigern Sie Ihren Umsatz',
  promoteConversionsWithOurAudience: 'Förderung von Konversionen mit unserem Publikum im gesamten Karma-Ökosystem.',
  averageROAS: 'Durchschnittlicher ROAS',
  increaseInAOV: 'Erhöhung des AOV',
  useKarmaToPlanTheirNextPurchase: 'Nutzen Sie Karma, um deren nächsten Einkauf zu planen',
  takeYourPlaceInOurTargeted:
    'Nehmen Sie Ihren Platz in unseren zielorientierten Newslettern ein und bleiben Sie auf dem Laufenden',
  getMoreEyesOnYourLatest:
    'Mit unseren Newsletter-Placements, die an Käufer gesendet werden, die ihren Posteingang nach günstigen Angeboten durchsuchen, erhalten Sie mehr Aufmerksamkeit für Ihre neuesten Angebote und Kollektionen.',
  multiBrandNewsletters: 'Mehrmarken-Newsletter',
  dedicatedNewsletters: 'Spezielle Newsletter',
  emailBanners: 'E-Mail-Banner',
  getDiscoveredWithPlacements: 'Werden Sie mit unseren Homepage-Platzierungen entdeckt ',
  weCreateVarietyShoppable:
    'Wir erstellen eine Vielzahl von kaufbaren Inhalten, die den Traffic dorthin bringen, wo Sie ihn brauchen.',
  dashboardSaleBanners: 'Dashboard-Verkaufsbanner',
  dailyFeaturedPlacements: 'Täglich angezeigte Platzierungen',
  dedicatedPopUpMessages: 'Spezielle Popup-Nachrichten',
  reachShoppersWherever: 'Erreichen Sie Käufer, wo immer sie sind, mit Echtzeit-Benachrichtigungen',
  targetedPushNotificationsMobileAndWeb:
    'Gezielte Push-Benachrichtigungen auf dem Handy und im Web schaffen einen weiteren Touchpoint für Ihre Marke und führen zu hohen Engagement- und Konversionsraten.',
  meetOurShoppers: 'Lernen Sie unsere Käufer kennen',
  meetOurShoppersDescription:
    'Nutzen Sie unsere leistungsorientierten Marketinglösungen, um sich bei Käufern mit hoher Kaufabsicht zu profilieren. Mit unseren plattformübergreifenden Platzierungen erreichen Sie die Nutzer, wo auch immer sie sind.',
  audienceInfoFirstNumber: '{{number, number}}{{unit}}+',
  audienceInfoFirstTitle: 'Nutzer',
  audienceInfoSecondNumber: '{{number, number}}{{unit}}+',
  audienceInfoSecondTitle: 'Monatliche Besuche',
  audienceInfoThirdNumber: '{{number, number}}{{unit}}+',
  audienceInfoThirdTitle: 'Gespeicherte Artikel',
  audienceInfoFourthNumber: '{{value, number}} %',
  audienceInfoFourthTitle: 'In den USA ansässige Benutzer',
  audienceInfoFifthNumber: '{{value, number}} %',
  audienceInfoFifthTitle: 'Generation Z und Millennials',
  audienceInfoSixthNumber: '{{value, number}} %',
  audienceInfoSixthTitle: 'Käuferinnen',
  ourUsersLoveUs: 'Unsere Nutzer lieben uns!',
  partnershipsReview1:
    'Eine tolle Erweiterung! Ich benutze sie schon seit Jahren und es ist toll, viele Produkte an einem Ort zu speichern – das macht das Online-Shopping so viel weniger stressig!',
  partnershipsReviewAuthor1: 'Millie Robinson',
  partnershipsReview2:
    'Als jemand, der sehr viel online einkauft, kann ich ehrlich sagen, dass Karma aus vielen Gründen der beste Freund eines Käufers ist ...',
  partnershipsReviewAuthor2: 'Sadie Mae',
  partnershipsReview3:
    'Beste Shopping-App im Play Store. So einfach zu nutzen. Ich habe alle meine gespeicherten Artikel in meinen Lieblingsgeschäften in einer App! 👍🏻',
  partnershipsReviewAuthor3: 'Kimberly Rogers',
  performanceBasis: 'Leistungsbasis',
  fixedFee: 'Fixed fee',
  thanksForSubmitting: 'Vielen Dank für die Einsendung!',
  someoneFromOurPartnershipsTeamWillBeInTouchShortly:
    'Jemand von unserem Partnerschaftsteam wird sich in Kürze bei Ihnen melden.',
  letsKickStartOurPartnership: 'Starten wir unsere Partnerschaft!',
  emailOrPhoneNumber: 'E-Mail-Adresse oder Telefonnummer',
  retailerName: 'Einzelhändlername',
  affiliateNetwork: 'Partnernetzwerk',
  budgetStructure: 'Budgetstruktur',
  budget: 'Budget',
  tellUsMore: 'Erzählen Sie uns mehr',
  pleaseFillInAllRequiredFields: 'Bitte füllen Sie alle erforderlichen Felder aus',
  partnerships: 'Partnerschaft',
  weCannotSaveThisItem: 'Wir können diesen Artikel nicht speichern,<1/> da dieser Shop nicht unterstützt wird ',
  theresCoupon: 'Es gibt einen Gutschein!',
  yourItemIsOffWithCode:
    'Mit dem Code {{code}} erhalten Sie bei {{- name}} {{value, number}}% Rabatt auf Ihren Artikel. ',
  muteAllNotification: 'All Beanachrichtigungen stummschalten',
  unMuteAllNotification: 'Benachrichtigungensstummschaltung aufheben',
  notificationSettings: 'Benachrichtigungseinstellungen',
  deleteNotification: 'Benachrichtigung löschen',
  notificationDeleted: 'Benachrichtigung gelöscht',
  itemMuted: 'Artikel stummgeschaltet',
  itemUnMuted: 'Artikelstummschaltung aufgehoben',
  priceDrop: 'Preissenkung',
  superDeal: '🔥 Superangebot',
  notSureWhenToBuy:
    'Nicht sicher, was Sie kaufen wollen? Erstellen Sie ein kostenloses Konto und wir benachrichtigen Sie bei Angeboten.',
  tcMayApply: 'Möglicherweise gelten die AGBs',
  earnKarmaCash: 'Verdienen Sie bis zu {{percent, number}} % Karma Cash.',
  saveToKarma: 'Artikel in Karma speichern',
  getKarmaToTrackItem: 'Holen Sie sich Karma, um Artikel zu verfolgen.',
  itemSaved: 'Gegenstand gespeichert!',
  youCanNowFindItem: 'Sie können Ihren Artikel jetzt in Ihrem Karma-Dashboard finden.',
  getKarmaExtension: 'Holen Sie sich die Karma-Erweiterung, um <1/> dieses Must-Have zu verfolgen.',
  notifyYou: 'Wir werden Sie benachrichtigen, wenn der beste Zeitpunkt zum Kauf gekommen ist!',
  downloadTheExtension: 'Download der Erweiterung',
  outOfStock: 'Nicht auf Lager',
  invalidEmailError: 'Ungültige E-Mail',
  collapse: 'Einklappen',
  expand: 'Ausklappen',
  lists: 'Listen',
  organizeYourItemsIntoWishListsWithJustAClick: 'Organisieren Sie Ihre Artikel in Wunschlisten mit nur einem Klick.',
  newItem: 'Neuer Artikel',
  helpCenter: 'Hilfezentrum',
  priceHighToLow: 'Preis hoch bis niedrig',
  priceLowToHigh: 'Preis niedrig bis hoch',
  sale: 'Sale',
  filterBy: 'Filtern nach:',
  karmaExclusive: 'K Exklusiv',
  noItemsFound: 'Keine Artikel gefunden',
  select: 'Auswählen',
  likeSneakersOrBlackFriday: 'Wie „Sneakers“ oder „Black Friday“',
  updateList: 'Liste aktualisieren',
  firstName: 'Vorname',
  lastName: 'Nachname',
  enterFirstName: 'Vornamen eingeben',
  enterLastName: 'Nachnamen eingeben',
  recentSearches: 'Jüngste Suchanfragen',
  addYourFavoriteStores: 'Fügen Sie Ihre Lieblingsgeschäfte hinzufügen',
  toAccessTheirLatestCoupons: 'um Zugang zu den neuesten Gutscheinen zu erhalten',
  logosProvidedByClearbit: 'Logos bereitgestellt von Clearbit',
  dateOfBirth: 'Geburtsdatum',
  weHaveEmailedYouALinkToResetYourPassword:
    'Sie haben per E-Mail einen Link erhalten, mit dem Sie Ihr Passwort zurücksetzen können.',
  didNotGetIt: 'Nicht erhalten?',
  profile: 'Profil',
  promotionsAndActivity: 'Werbeaktionen und Aktivitäten',
  couponsForFavoriteStores: 'Gutscheine für Lieblingsgeschäfte',
  accountActivity: 'Kontoaktivität',
  powerShopper: 'Power-Shopper? 💪',
  enterLinkToAnyItemPage: 'Geben Sie einen Link zu einer beliebigen Artikelseite ein',
  saveStuffWithZeroFuss: '<0>Holen Sie sich die Browsererweiterung</0> und speichern Sie Ihre Daten ohne viel Wirbel.',
  viewMore: 'Mehr sehen',
  tooHotToMiss: 'Zu heiß zum Verpassen',

  // WebsiteLandingKarmaOld
  joinShoppers: 'Schließen Sie sich {{number, number}} Million Shopper an',
  waysToUse: 'Verwendung von Karma',
  youCanUseKarma:
    'Sie können Karma auf vielfältige Weise nutzen, z. B. um sicherzustellen, dass Sie den besten Preis zur richtigen Zeit erhalten, und um Ihre nächsten Einkäufe zu planen. Hier sind ein paar Beispiele, die wir empfehlen:',
  livesInBrowser: 'Karma lebt in Ihrem Browser',
  browserExtension: 'Die Browser-Erweiterung von Karma trifft Sie überall dort, wo Sie im Internet shoppen.',
  helpsYouPlan: 'Hilft Ihnen, Ihren nächsten Einkauf zu planen',
  notReadyToBuyJust:
    'Noch nicht zum Kauf bereit? Speichern Sie mit Karmas Browsererweiterung für später und kaufen Sie mit mehr Bedacht ein. Sie können alle Ihre gespeicherten Artikel an einem Ort im Auge behalten. ',
  automaticallyFindsCouponCodes: 'Findet automatisch Gutscheincodes',
  findsAndAppliesCodes:
    'Karma findet automatisch den besten Gutschein für Sie und wendet ihn an, damit Sie sicher sein können, dass Sie jedes Mal den besten Preis bekommen.',
  soYouCanBuyAtTheRightTime: 'Damit Sie zum richtigen Zeitpunkt kaufen können',
  usesCleverTechnologies:
    'Mit Hilfe intelligenter Technologie verfolgen Karma, ob der Preis sinkt oder wann die Ware wieder auf Lager ist, damit Sie zum richtigen Zeitpunkt kaufen können. ',
  notOnlySave:
    'Karma spart nicht nur Geld - Sie verdienen auch Geld! Keine Punkte. Keine Gimmicks. Sie verdienen bei jedem Einkauf echtes Geld.',
  makeImpact: 'Setzen Sie Zeichen!',
  donate:
    'Kaufen Sie wie gewohnt ein und beobachten Sie, wie Karma etwas zurückgibt. Für jeden Einkauf über {{over, number}}$ spenden wir {{dollars, number}} $ an einen Zweck Ihrer Wahl.',
  accessToOffers: 'Zugriff auf exklusive Angebote',
  accessItems:
    'Greifen Sie auf Ihre gespeicherten Artikel an einem Ort zu, entdecken Sie exklusive Angebote und sehen Sie, was weltweit auf Karma angesagt ist.',
  hereIsHowItWorks: 'So funktioniert es',
  learnMoreAboutGives: 'Erfahren Sie mehr über Karma Gives',
  alwaysKnowWhenPricesDrop: 'Immer wissen, wenn die Preise fallen',
  getNotifiedWhenBackInStock: 'Benachrichtigt werden, wenn es wieder vorrätig wird',
  findsYouBestCoupons: 'Findet für Sie die besten Gutscheine',
  getKarmaCashWhenYouShop: 'Erhalten Sie Karma Cash, wenn Sie einkaufen',
  easilySaveAllThings: 'Sparen Sie bei all Ihren Lieblingsdingen in über {{value, number}}.000 Geschäften',
  karmaProvidesGreatTechnology:
    'Karma bietet Ihnen eine großartige Technologie und Flexibilität, mit der Sie intelligenter einkaufen und unnötige Impulskäufe vermeiden können, während Sie gleichzeitig Zeit und Geld sparen.',

  accountActivityAndImportantInformation: 'Kontoaktivitäten und wichtige Informationen',
  addKarmaToYourDesktop: 'Füge Karma zu deinem Desktop hinzu, damit du<br/>während der Arbeitszeit einkaufen kannst.',
  addNumberItemsToList: '{{value}} Artikel zur Liste hinzufügen',
  addOneItemToList: '1 Artikel zur Liste hinzufügen',
  addStuffYouFindToThisListToKeepItOrganized:
    'Füge dieser Liste Dinge hinzu, die du findest, um organisiert zu bleiben.',
  alrightBut: 'Alles klar, aber du wirst was verpassen!',
  areYouSureYouWantToDisconnectFromPaypal: 'Bist du sicher, dass du die Verbindung zu Paypal trennen willst?',
  balance: 'Guthaben',
  buyWhateverYouWant: 'Kaufe, was du willst, und mach die Bankfrau glücklich. ',
  cashIsBack: '<1>Cash</1> ist zurück',
  cashIsBackColoredRight: 'Cash ist <1>zurück</1>',
  cashOutRewards: 'Belohnungen auszahlen',
  checkItOffAsYouBuy: 'Hake Artikel beim Kauf ab',
  congratulationsOnCashout: 'Glückwunsch! Du kannst dir jetzt deine Belohnungen auszahlen lassen.',
  connectToPayPalToCashOut: 'Verbinde ein PayPal-Konto, um dir deine Belohnungen auszahlen zu lassen',
  connectYourPaypalAccountToCashOutRewards: 'Verbinde dein PayPal-Konto, um dir deine Belohnungen auszahlen zu lassen.',
  downloadOurApp: 'Lade unsere App herunter und nimm <1>Karma to-go</1>',
  dropSomeShoppingInspo: 'Gib deiner Familie ein paar Shopping-Inspirationen.',
  earnKarmaCashShort: 'Bis zu {{percent, number}} % K Cash',
  earned: 'Verdient',
  enterYourPassword: 'Passwort eingeben',
  firstAddToList: 'Zunächst zur Liste hinzufügen',
  followOnLinkedin: 'Folge uns auf LinkedIn',
  freshOutCoupons: 'Keine neuen Coupons',
  getBuzzedWhenYourWishes:
    'Lass dich benachrichtigen 🐝, wenn deine „Wünsche“ im Preis fallen oder wieder auf Lager sind.',
  getIt: 'Hol es dir',
  getTheApp: 'Hol dir die App',
  gotIt: 'Verstanden',
  gotStuck: 'Stecken geblieben? Klicke <1>hier</1>, um weiterzukommen.',
  happyShoppers: '👉 Über {{count, number}} Mio. glückliche Käufer/innen',
  howElseCanWeImproveKarma: 'Wie können wir Karma noch verbessern?',
  iNeedThisInMyLife: 'Ich brauche das in meinem Leben',
  info: 'Infos',
  itemUpdatesUnsubscribe: 'Artikel-Updates (Preissenkungen, Bestandsaufstockungen und mehr)',
  itsAGhostTown: 'Es ist eine Geisterstadt',
  joinTheTeam: 'Tritt dem Team bei',
  karmaGetsYouTheLowestPossiblePrice: 'Mit Karma erhältst du immer den niedrigsten Preis 👏.',
  karmaIsAnApp: 'Karma ist eine App und Erweiterung, die dir hilft, beim Online-Shopping Zeit und Geld zu sparen.',
  karmaYourFavoriteBrands: '<1>Karma</1> deine Lieblingsmarken',
  karmaYourFavoriteBrandsMobile: '<1>Karma</1> deine Lieblingsmarken',
  knowHowToShopBetter: 'Weiß, wie man <1>besser einkauft<1> als alle anderen ',
  letsPlay: 'Lasst uns spielen',
  lolWereAVerbNow: '(lol, wir sind jetzt ein Verb)',
  lookAtYouLittleScroller: 'Sieh dich an, du kleiner Scroller. Herzlichen Glückwunsch, dass du so weit gekommen bist.',
  lookAtYouLittleScrollerMobile: 'Sieh dich an, du kleiner Scroller.',
  lookingForInfluencer: 'Suchst du nach Werbepartnerschaften?',
  markItemsYouveMadeYourOwnItFulfillsSoGood: 'Markiere Artikel, die du dir gekauft hast. Das fühlt sich so gut an!',
  markNumberItemsAsFullfilled: 'Du bist dabei, {{value}} Artikel abzuhaken',
  meetYouAtIn: 'Wir sehen uns in 3 Sekunden bei {{retailer}} ...',
  missOut: 'verpassen',
  missingYouAlready: 'Wir vermissen dich jetzt schon!',
  money: 'zahlen',
  never: 'Nichts',
  nevermind: 'Egal',
  noFomoHere:
    'Wir haben keine Angst, etwas zu verpassen. Wir benachrichtigen dich, wenn deine „Wünsche“ im Preis fallen oder wieder auf Lager sind.',
  noNotificationsYet: 'Du bist auf dem neusten Stand',
  noNotificationsYet2: 'Sobald es eine Preissenkung oder ein Restock-Update gibt, findest du sie hier.',
  nowLetsTalPrivacy: 'Lass uns jetzt über <1>Privatsphäre<1/> sprechen',
  nowLetsTalkAboutPrivacy: 'Reden wir über den Datenschutz 🤫.',
  onlineShoppingButBetter: 'Online-Einkauf aber <1>besser</1>',
  orCopyTheLinkBelowAndSend: 'Oder kopiere den unten stehenden Link und sende ihn auf die altbewährte Weise.',
  other: 'Sonstiges',
  passwordValidationNote:
    'Muss aus mindestens 8 Zeichen bestehen, darunter ein Großbuchstabe, ein Kleinbuchstabe und eine Ziffer.',
  payLess: 'Weniger Geld',
  playFavesWithYourItems: 'Wähle deine Lieblingsartikel',
  preparingYourKarmaCash: 'Wir bereiten dein Karma Cash vor.',
  savingMoneyIsHotLetsGetOnThat: 'Sparen ist angesagt. Legen wir los ✌',
  search: 'Suche',
  seeHowToSave: 'So funktioniert das Speichern',
  skipForNow: 'Vorerst überspringen',
  soFarWeSavedYou: 'Bisher hast du dank uns folgenden Betrag gespart',
  sorryForInconvenience: 'Bitte entschuldige die Unannehmlichkeiten – wir versprechen, dass es sich lohnen wird.',
  starReviews: '👉 Über {{count, number}} Tsd. 4,7 Sterne Bewertungen',
  starTheThingsYouNeedInYourLifeForQuickAccess:
    'Markiere die Dinge, die du in deinem Leben brauchst, damit du schnell darauf zugreifen kannst.',
  stillWannaUnsubscribe: 'Willst du dich immer noch <1>von allen Karma-E-Mails abmelden</1>?',
  storePromotionsAndOtherMarketingEmails: 'Werbeaktionen und andere Marketing-E-Mails',
  storesWorldwide: '👉 Über {{count, number}} Tsd. Geschäfte weltweit',
  stuffWorthSavingGoesHere: 'Alles, was es sich zu speichern lohnt, kommt hierher 👇',
  sureYouWantToDeleteTheseNumberItems: 'Bist du sicher, dass du diese {{value}} Artikel löschen willst?',
  sureYouWantToDeleteThisList: 'Möchtest du diese Liste wirklich löschen?',
  takeControlOfYourInbox: 'Übernimm die Kontrolle über deinen Posteingang',
  theMotherOfAllWishLists: 'Die Mutter aller <1>Wunschlisten<1/>',
  thisFieldIsRequired: 'Dieses Feld ist erforderlich',
  toCashOutRewards:
    'Um dir deine Belohnungen auszahlen zu können, musst du einen Mindestbetrag von {{minimum, number}} $ erreichen.',
  toCashOutYouMustReach:
    'Um dir deine Belohnungen auszahlen zu können, musst du einen Mindestbetrag von {{currency}}{{value, number}} $ erreichen.',
  tryAgain: 'Erneut versuchen',
  trySearchingForSomethingElse: 'Suche nach etwas anderem.',
  updatePreferences: 'Einstellungen aktualisieren',
  wantIt: 'Ich will es',
  weAreBusySelfImproving: 'Wir sind damit beschäftigt, uns selbst zu verbessern',
  weAreVerySeriousAboutPrivacy:
    'Wir nehmen den Datenschutz sehr ernst und werden deine Daten niemals an Dritte verkaufen. Niemals.',
  weDontSellYourData: 'Wir verkaufen deine Daten nicht',
  weGotStuckOnOurWay: 'Wir sind auf dem Weg zu {{retailer}} stecken geblieben!',
  weHitYouUp: 'Wir werden dich benachrichtigen, sobald wir einen Promo-Code für dieses Geschäft finden.',
  weHustleForCoupons: 'Wir legen uns ins Zeug für Gutscheine',
  weUseBankLevelSecurity: 'Wir verwenden Sicherheit auf Bankniveau',
  wellApplyCodesThatSaveYouMoney: 'Wir wenden Codes an, mit denen du $$$ sparen kannst. ',
  wellTrackAndScanForCoupons:
    'Wir suchen und scannen nach Coupons, damit du es nicht tun musst. <br/>(ok, wir nicht, aber unsere KI schon)',
  wellTrackAndScanForCouponsMobile: 'Wir suchen und scannen nach Coupons, damit du es nicht tun musst.',
  wereAVerbNowLol: '(wir sind jetzt ein Verb, lol)',
  wereGoingShopping: 'Wir gehen einkaufen',
  wereHereToMakeItYours: 'Wir sind hier, um es <1>#zu_deinem_zumachen</1>',
  whyUnsubscribeFromAllOurMoneySavingEmails:
    'Warum von all unseren geldsparenden E-Mails abmelden, wenn du nur die deaktivieren kannst, die nicht relevant sind?',
  withKarmaYouCanBuy: 'Mit Karma kannst du kaufen,<br />was du willst und<br />die Bankfrau bei Laune halten.',
  withKarmaYourCash: 'Mit Karma kommt dein Geld zu dir zurück wie eine Art magisches Voodoo-Geld.',
  yesDisconnect: 'Ja, Verbindung trennen',
  yesIDoThat: 'Ja, das tue ich',
  youHaveToReconnectToWithdraw: 'Um dein Karma Cash abzuheben, musst du die Verbindung wiederherstellen.',
  youNeedToSaveItemsBeforeYouCanShareYourList: 'Du musst Artikel speichern, bevor du deine Liste teilen kannst.',
  youReATen: 'Du bist eine 10 und zahlst mit Karma? Kaufe online ein und erhalte immer den niedrigsten Preis 👏.',
  youReAboutToMuteNumberItems: 'Du bist dabei, Benachrichtigungen für {{value}} Artikel stumm zu schalten',
  yourCashWillComeBackToYou: 'Dein Geld kommt zu dir zurück wie eine Art magisches Voodoo-Geld. ',
  yourInformationIsSecured:
    'Deine Daten werden mit der neuesten 256-Bit-Verschlüsselung gesichert, um einen vollständigen Schutz zu gewährleisten.',
  yourSearchCameUpEmpty: 'Deine Suche hat nichts ergeben',
  total: 'Summe',
  keepAnEyeOutForTheStatusUpdatesWeWillSendToEmail:
    'Halten Sie Ausschau nach den Status-Updates, die wir an {{email}} schicken werden.',
  psaDidYouKnow: 'PSA: Wussten Sie, dass Sie',
  addWishItemsFromAnyBrand:
    'Füge „Wunsch“-Artikel einer beliebigen Marke hinzu und warte, bis wir sagen, dass es Zeit ist, sie zu kaufen.',
  capturePurchaseReadyShoppers: 'Fangen Sie kaufbereite Kunden ein, indem Sie Ihre heißesten Gutscheine präsentieren.',
  gainFullFunnelInsights: 'Gewinnen Sie mit jedem gespeicherten Artikel Einblicke in die Kaufabsicht.',
  boostCartEngagement: 'Steigern Sie das Engagement im Warenkorb mit unseren Echtzeit-Artikelaktualisierungen.',
  promoteLongTermGrowth:
    'Fördern Sie langfristiges Wachstum durch maßgeschneiderte Kampagnen innerhalb des Karma-Ökosystems.',
  reachNewAudiences: 'Erreichen Sie neue Zielgruppen durch vielfältige, auf Ihre Marke zugeschnittene Kampagnen',
  influencerCollaborations: 'Zusammenarbeit mit Influencern',
  teamUpWithTopInfluences: 'Arbeiten Sie mit Top-Influencern zusammen, die am besten zur Identität Ihrer Marke passen.',
  socialPosts: 'Soziale Netzwerke',
  giveANewFollowing: 'Gewinnen Sie mit maßgeschneiderten, inhaltsreichen Kampagnen eine neue Anhängerschaft.',
  brandFocusedNewsletters: 'Auf die Marke ausgerichtete Newsletter',
  letYourOffersShine: 'Lassen Sie Ihre Angebote in den mit Spannung erwarteten kuratierten E-Mails erstrahlen.',
  driveTrafficWhenYouNeedIt:
    'Lenken Sie den Verkehr dorthin, wo Sie ihn brauchen, mit unseren kaufbaren Homepage-Placements',
  dashboardBanners: 'Dashboard-Verkaufsbanner',
  flauntYourLatestOfferings: 'Präsentieren Sie Ihre neuesten Angebote mit auffälligem Bildmaterial.',
  dailyFeatured: 'Tagesaktuell',
  highlightDeals: 'Heben Sie Geschäfte dort hervor, wo es am wichtigsten ist.',
  dedicatedPopUps: 'Spezielle Popup-Nachrichten',
  activelyServeYourPromos: 'Bieten Sie Ihre Werbeaktionen aktiv an, damit sie nicht übersehen werden können.',
  createAnotherTouchpointForYourBrand: 'Schaffen Sie einen weiteren Touchpoint für Ihre Marke',
  increaseEngagement:
    'Steigern Sie Engagement und Konversionsraten durch gezielte Push-Benachrichtigungen auf dem Handy und im Web.',
  karmaIsOnlineShoppingButBetter: 'Karma ist wie Online-Shopping, nur besser.',
  transactionsNumber: '👉 {{count, number}}M+ Transaktionen',
  partnershipsReview4: 'Das ist ein fantastischer Weg, um Ihre Wunschliste zu erstellen und Geld zu sparen!',
  partnershipsReviewAuthor4: 'Emily Smith',
  partnershipsReview5:
    'Es ist buchstäblich die beste Erweiterung, die ich je heruntergeladen habe. Es ist das Beste, um Geld zu sparen und gleichzeitig Geld zu bekommen!',
  partnershipsReviewAuthor5: 'Selena Roblox',
  noNeedToSlideIntoOurDMs:
    "Sie müssen nicht in unsere DM's rutschen (Sie könnten es aber 😏). Unser Team ist für alles da, was Sie brauchen.",
  supportIssues: 'Fragen zur Unterstützung',
  adsAndPartnerships: 'Partnerschaft',
  jobOpportunities: 'Arbeitsmöglichkeiten',
  publicRelations: 'Öffentlichkeitsarbeit',
  letsChat: 'Plaudern wir ein wenig',
  earnings: 'Ergebnis',
  allTime: 'Alle Zeiten',
  ordersMayTakeHoursToAppear: 'Es kann bis zu {{value, number}} Stunden dauern, bis eine Bestellung erscheint.',
  youHaventReceivedAnyPayoutsYet: 'Sie haben noch keine Auszahlungen erhalten ',
  alerts: 'Warnungen',
  featuresReview1:
    'Ich fügte ein Paar Turnschuhe zu meiner Wunschliste hinzu und bekam buchstäblich am nächsten Tag einen Ping, dass es 50 % reduziert war. Wie konnte ich vor euch überhaupt etwas kaufen?',
  featuresReviewAuthor1: 'Sandra C. Martinez, App-Store-Rezensentin',
  featuresReview2:
    'Das ist wirklich die beste Smart-Shopping-App und ich kenne noch ein paar andere. Ich habe sogar das Gefühl, dass ich das System ein wenig betrüge, weil ich tatsächlich Geld spare! Ich habe immer den vollen Preis bezahlt und fühlte mich wie ein Trottel',
  featuresReviewAuthor2: 'Gertie D. Ackerman, App-Store-Rezensentin',
  featuresReview3:
    'Ich habe einen neuen Laptop gekauft, der bereits um 25 % reduziert war. Als ich auf „Mit Karma bezahlen“ drückte, stellte ich fest, dass die Summe noch geringer war. Das passiert jedes Mal, wenn ich mit der App bezahle',
  featuresReviewAuthor3: 'Irene J. Cox, App-Store-Rezensentin',
  downloadNow: 'Jetzt herunterladen',
  wannaBeDeskBuddies: 'Wollen Sie Schreibtischnachbarn sein?',
  seeOpenPositions: 'Offene Positionen',
  join: 'Mitmachen',
  weLiveAndSwearBy: 'Wir leben und schwören auf ...',
  goodKarma: 'Gutes Karma',
  weBelieveThatGoodVibes: 'Wir glauben, dass ~good vibes~ ein Lebensstil sind, und wir halten uns daran.',
  beingTrue: 'Wahrhaftig sein',
  theresNoUSvsU: 'Es gibt kein UNS gegen SIE. Unsere Tische sind immer rund und wir halten Ihnen einen Platz frei.',
  passion: 'Leidenschaft',
  weHaveThatFireYouKnow:
    'Wir haben dieses Feuer, wissen Sie? Wir arbeiten mit Tatkraft, Ausdauer und Liebe zu dem, was wir tun.',
  entrepreneurship: 'Unternehmertum',
  weWantItWeGetIt:
    'Wir wollen es – wir bekommen es. Wir haben keine Zeit für endlose Zooms. Wir nehmen uns, was wir wollen.',
  mutuality: 'Gegenseitigkeit',
  wereMadeUpOfAwesomePeople:
    'Wir bestehen aus großartigen Menschen, die wissen, dass Teamarbeit nicht genug ist. Wir kümmern uns tatsächlich umeinander.',
  buildCustomerLoyalty: 'Binden Sie Ihre Kunden mit sofortigen Geldprämien',
  itemSaving: 'Artikel sparen',
  itemUpdates: 'Aktualisierte Artikel',
  backInStockAlerts: 'Wieder auf Lager',
  lowInStockAlerts: 'Nur wenig(e) auf Lager',
  storePromotions: 'Werbeaktionen im Laden',
  exclusive: 'exklusiv',
  couponCopied: 'Coupon kopiert',
  completedDesc:
    'Das Geschäft hat Ihre Bestellung bestätigt. Verdientes Karma Cash kann {{days, number}} Tage ab Kaufdatum und erst nach Erreichen eines Mindestbetrags von {{value, number}} {{currency}} abgehoben werden.',
  notQualifiedDesc: 'Für die von Ihnen bestellten Artikel können zusätzliche Einschränkungen gelten.',
  pendingDesc:
    'Das Geschäft hat Ihre Bestellung erhalten. Sie kann bis zu {{days, number}} Tage lang als ausstehend angezeigt werden, je nach den Rückgabebestimmungen des Geschäfts.',
  cancelledDesc:
    'Das Geschäft hat uns mitgeteilt, dass Ihre Bestellung entweder storniert oder vollständig erstattet wurde.',
  downloadNowItsFree: 'Jetzt herunterladen – es ist kostenlos',
  weLiveToMakeOurShoppersHappy: 'Wir leben, um unsere Kunden glücklich zu machen',
  moneySavedForYou: 'Für Sie eingespartes Geld',
  happyShoppers2: 'Zufriedene Einkäufer',
  starReviews2: '{{value, number}} Sternbewertungen',
  storesWorldwide2: 'Läden weltweit',
  whyYouWill: 'Warum Sie',
  it: 'es',
  multiBrandWishLists:
    'Multi-Marken-Wunschlisten? Echtes Gänsehautgefühl. Entdecken Sie etwas, das Ihnen von einer beliebigen Marke gefällt, fügen Sie es zu Ihrer Karma-Liste hinzu und warten Sie, bis wir sagen, dass es Zeit ist, sie zu kaufen.',
  addKarmaToYourMobile: 'Laden Sie <br />unsere App<br /> herunter und genießen Sie <br />Karma to-go',
  appStoreReview1:
    'Ich fügte eine Lidschattenpalette zu meiner Liste hinzu und erhielt am nächsten Tag eine Benachrichtigung, dass sie zu 50 % reduziert war. Danke!!',
  appStoreReview2:
    'Die Tatsache, dass der niedrigste Preis immer direkt in meiner Einkaufstasche liegt, ist so gefährlich. Aber ich liebe es auch',
  appStoreReview3:
    'Ich habe gerade den günstigsten Preis für neue Kopfhörer erzielt, während ich auf den Bus gewartet habe. Es ist einfach zu einfach',
  appStoreReviewBy: 'App Store Bewertung von: {{Name}}',
  yourInfoSafeWithUs: 'Ihre Daten sind bei uns sicher',
  whyGiveOutYourCCDeets: 'Warum sollten Sie Ihre CC-Daten weitergeben, wenn Sie sie an einem Ort aufbewahren können?',
  partyForOne: 'Party für 1 Person',
  wellNeverSellYourDataToAnyThirdParties:
    'Wir werden Ihre Daten niemals an Dritte verkaufen, Punkt. Mit Sicherheit auf Bankniveau sind Ihre Daten vor neugierigen Blicken geschützt.',
  buyWithoutATrace: 'Kaufen ohne Spuren zu hinterlassen',
  weWontLetAnyoneTrackYourShoppingHabits:
    'Wir lassen nicht zu, dass jemand Ihre Einkaufsgewohnheiten verfolgt. Hier gibt es kein Urteil.',
  spotSomethingYouLoveFromAnyBrand:
    'Entdecke etwas von einer beliebigen Marke, das dir gefällt, füge es zu deiner Karma-Liste hinzu und warte, bis wir sagen, dass es Zeit ist, es zu kaufen.',
  needIt: 'Brauche es',
  appStoreReviewer: 'App Store-Rezensent',
  appStoreReview4:
    'Ich verlasse mich so sehr auf diese App, um den Überblick über alle meine Wunschartikel an einem Ort zu behalten und sie zum bestmöglichen Preis zu bekommen.',
  appStoreReview5: 'Diese App hat mir geholfen, so viel Geld zu sparen.',
  wishlist: 'Wunschzettel',
  getTheLowestPriceAtAllYourFavoriteBrands: 'Holen Sie sich die niedrigsten Preise für alle Ihre Lieblingsmarken.',
  getTheAppForFree: 'Holen Sie sich die App – kostenlos',
  addKarmaSpaced:
    'Fügen Sie Karma<br />zu Ihrem Desktop hinzu,<br />damit Sie während der <br />Arbeitszeit einkaufen können.',
  spotSomethingYouLoveFromAnywhere:
    'Entdecken Sie etwas, das Ihnen gefällt, fügen Sie es zu Ihrer Karma-Liste hinzu und bleiben Sie dort, bis wir sagen, dass es Zeit ist zu gehen.',
  getCouponsCashbackWithPeriod:
    'Erhalten Sie Gutscheine, Bargeldrückerstattungen und Benachrichtigungen über Preissenkungen.<br /> Zahlen Sie immer den niedrigsten Preis.',
  getCouponsCashbackWithAnd:
    'Holen Sie sich Gutscheine, Bargeldrückerstattungen, Benachrichtigungen über Preissenkungen und <br />zahlen Sie immer den niedrigsten Preis.',
  clickTheLoginLinkFromYourPhone:
    'Klicken Sie auf den Anmeldelink auf Ihrem Telefon, um auf die Karma-App zuzugreifen.',
  getItOn: "Weiter geht's",
  downloadOnThe: 'Herunterladen auf der',
  likeShoppingFromYourComputer: 'Sie kaufen gerne von Ihrem Computer aus ein?',
  getTheKarmaExtensionToShopEvenBetter: 'Holen Sie sich die Karma-Erweiterung, um noch besser einzukaufen.',
  headerMobileLogin: 'Karma | Installieren',
  noFomoHereMobile:
    'Wir haben keine Angst, etwas zu verpassen. Wir benachrichtigen Sie, wenn Ihre „Wünsche“ im Preis fallen oder wieder auf Lager sind.',
  withKarmaYourCashMobile: 'Mit Karma kommt Ihr Geld zu Ihnen zurück wie eine Art magisches Voodoo-Geld.',
  spotSomethigYouLoveMobile:
    'Entdecken Sie etwas, das Ihnen gefällt, fügen Sie es zu Ihrer Karma-Liste hinzu und bleiben Sie dort, bis wir sagen, dass es Zeit ist zu gehen.',
  wellTrackAndScanForCouponsMobile2:
    'Wir suchen und scannen nach Coupons, damit Sie es nicht tun müssen. (ok, wir nicht, aber unsere KI schon)',
  weHustle: 'Wir sind auf der Jagd',
  forCoupons: 'nach <1>Gutscheinen</1>',
  theMotherOfAll: 'Die Mutter aller',
  wishlists2: 'Wunschlisten',

  // NEW PHRASES
  moneySavedForUsers: 'Money saved for users',
  whatItsLikeWorkingAtKarma2: 'What’s it like working at Karma?',
  makeItYours: 'Make it yours',
  continueWith: 'Continue with {{provider}}',
  onItsWay: 'On its way...',
  weVeEmailedInstructionsForResettingYourPassword:
    'We’ve emailed instructions for resetting your password to {{email}}',
  wrongEmailTryAgain: 'Wrong email? Try again',
  goToEmail: 'Go to email',
  heyYouLetsJumpBackIntoSaving: 'Hey you! Let’s jump back into saving',
  firstConfirmItsYou: 'First, confirm it’s you',
  letsDoThis: 'Let’s do this',
  signUpAndGet: 'Sign up and get',
  moneyOnUs: '{{currency}}{{value, number}} on us',
  usersHeroTitle: 'Your\nbank account’s gonna love this.',
  tellMeMore: 'Tell me more',
  createNewPassword: 'Create new password',
  joinKarma: 'Join Karma',
  whyYouLoveMerchantsTitleDesktop3: '<1>One-swipe<1/> checkout is yours',
  whyYouLoveMerchantsSubtitle3: 'Pay with Karma works with all retail platforms, with zero\nintegration and no fees.',
  saveItemsFromStores: 'Save items from your favorite stores with a single click',
  easilySaveAndKeep:
    'Easily save and keep track of everything you want to buy. The best\npart? You’ll get notified the second any of your saved items\ngo on sale, or come back in stock.',
  addTheFreeKarma:
    "Add the free Karma button to your browser so you can\neasily keep track of everything you want to buy. The best\npart? You'll get notified the second any of your saved items\ngo on sale, drop in price, or come back in stock.",
  getButtonAndGetEverything: 'Get the free Karma button and\nget everything you want on sale',
  getKarmaItIsFree: "Get Karma - It's Free!",
  onYourPhone: 'On your phone? Save items on the go with our free app',
  hereHowTheButtonWorks: "Here's how the Karma\nbutton works",
  dontSellMyPersonalInformation: 'Don’t sell my personal information',
  postCode: 'Postcode',
  ifThisWontHelp: "If this won't help, ",
  contactSupport: 'contact our support center.',
  wellKeepAnEyeOnIt: 'We’ll keep an eye on it & will let u know ASAP if the price drops',
  seeYourSavedItems: 'See your saved items',
  logInAndSaveItemToKarma: 'Login & Save item to Karma',
  blogPromotions: 'Blog promotions',
  expiringIn: 'Expiring in',
  goToStore: 'Go to store',
  visitStorePage: 'Visit store page',
  favStoresUpdated: 'Your favorite stores were successfully updated.',
  availableCoupons_plural: '{{count}} available coupons',
  availableCoupons: '{{count}} available coupon',
  findAndComparedTheBestDeals: 'Finds and compares the best deals',
  karmaHelpsYouShopSmarter: 'Karma helps you shop smarter by instantly comparing products from different retailers.',
  expiring: 'Expiring',
  today: 'Today',
  tomorrow: 'Tomorrow',
  days: '{{count, number}} DAY',
  weeks: '{{count, number}} WEEK',
  months: '{{count, number}} MONTH',
  daysPlural: '{{count, number}} DAYS',
  weeksPlural: '{{count, number}} WEEKS',
  monthsPlural: '{{count, number}} MONTHS',
  tellUsWhyYouUninstalledTheExtension: 'Tell us why you uninstalled the extension',
  tooManyPopUpsWhileIShop: 'Too many pop-ups while I shop',
  couponsDontWork: 'Coupons don’t work',
  dataPrivacyConcerns: 'Data privacy concerns',
  causedProblemsWithMyBrowser: 'Caused problems with my browser',
  tellUsMoreOptional: 'Tell us more (optional):',
  shareYourThoughtsWithUs: 'Share your thoughts with us',
  myFavoriteStoresAreNotSupported: 'My favorite stores are not supported',
  notReadyToLetGoYet: 'Not ready to let go yet? Reinstall Karma with just one click',
  chatWithUsAndGet: 'Chat with us & get<br />${{count}} Amazon Gift Card',
  scheduleAMeetingAndEarnGiftCard:
    'Schedule a {{minutes}}-minute interview and earn a<br />${{count}} Amazon gift card.',
  scheduleAnInterview: 'Schedule an interview',
  compareProductsBeforeYouBuy: 'Compare products before you buy',
  downloadWithKarmaAndSaveMajorCash: 'Download Karma<br/>and save major $$$',
  youSearchedFor: 'You searched for',
  yourSavedItems: 'Your saved items',
  moreForYou: 'More for you',
  headerGlobalSearch: 'Karma | {{query}}',
  noResultsFoundFor: 'No results found for',
  pleaseReviewYourSpellingOrTryDifferentKeyword: 'Please review your spelling or try different keyword',
  heyThere: 'Hey, {{name}}',
  myAccount: 'My account',
  accountSettings: 'Account settings',
  trendingNow: 'Trending now',
  helpUsImproveKarma: 'Help us improve Karma',
  yourFavoriteStores: 'Your favorite stores',
  chooseYourFavoriteStores: 'Choose your favorite stores',
  viewStoreInfo: 'View store info',
  addStore: 'Add store',
  findMoreStores: 'Find more stores',
  whatAreYouShoppingFor: 'What are you shopping for?',
  searchForAnyItemBrandOrStore: 'Search for any item, brand or store',
  savedFromStore: 'Saved from store',
  manageFavoriteStores: 'Manage favorite stores',
  hasNoActiveCoupons: '{{retailer}} has no active coupons',
  checkOutOtherStores: 'Check out other stores for more deals',
  youCanStillEarn: 'You can still earn {{number}}% cashback with Karma!',
  retailerCoupons: '{{retailer}} coupons',
  nothingSavedYet: 'Nothing saved yet?',
  useOurBrowserExtension:
    'Use our browser extension on your {{retailerName}} visit to quickly add items to your favorites. Your shopping journey starts here!',
  letsMakeItPersonal: 'Let’s make it personal',
  selectAndTrack: 'Select your favorite stores and track coupons and deals',
  chooseYourFaves: 'Choose your faves',
  code: 'Code',
  itemFound: '{{count, number}} item found',
  itemFound_plural: '{{count, number}} items found',
  storeFound: '{{count, number}} store found',
  storeFound_plural: '{{count, number}} stores found',
  itemsTitle: 'Items',
  storeInfo: 'Store info',
  preOwned: 'Pre-owned',
  refurbished: 'Refurbished',
  favorited: 'Favorited',
  allPromotionsOrOffers:
    "All promotions or offers by any of our partners are subject to the applicable partner's terms and conditions, which may be changed by the partner at its discretion. We may receive a commission for purchases made through our links or using our coupons. Eligibility for a welcome bonus is subject to section 5 of Karma’s Terms & Conditions.",
  shippingInfo: 'Shipping info.',
  freeShipping: 'Free shipping',
  overMinAmount: 'over {{currency}}{{value, number(minimumFractionDigits: 2)}}',
  delivers: 'Delivers',
  deliveryDays: '{{value, number}} days',
  freeReturns: 'Free returns',
  upToDays: 'up to {{value, number}} days',
  deliveryInfo: 'Delivery info.',
  returnsInfo: 'Returns info.',
  notAvailable: 'Not available',
  minOrderMayApply: 'Min. order may apply',
  hottestDeals: 'Hottest deals',
  discoverCouponsAndExclusiveOffers: 'Discover coupons and exclusive offers',
  moreFromStore: 'More from {{store}}',
  seeWhatsTrendingNow: 'See what’s trending now',
  automaticallyApplyCoupons:
    'Automatically apply coupons at checkout, save what you love and earn cashback — all with the Karma extension.',
  deleteMyAccount: 'Delete my account',
  areYouSureDeleteAccount: 'Are you sure you want to delete your account?',
  youHaveKarmaCash: 'You have {{currency}}{{value, number}} Karma Cash in your wallet.',
  deletingYourAccountWill:
    'Deleting your account will permanently remove all of your information, your saved items and your earnings from our database, and this action cannot be undone.',
  cancel: 'Cancel',
  accountDeletedSuccessfully: 'Account deleted successfully',
  shopOnTheGo: 'Shop on-the-go with Karma app',
  moveFromDevice: 'With Karma, effortlessly move from one device to another as you shop.',
  fewMomentsWeAreStillWorkingOnSavingItemsToThisList:
    'Few moments, we’re still working on saving items to this list... ',
  notificationDate: '{{date, shortFromNowDate}} ago',
  onboardingIOSTitle: 'Enable Karma on Safari \n to access our coupon scanner',
  enableKarmaOnSafari: 'Enable Karma on Safari to access our coupon scanner',
  alwaysAllowOnSites: 'Always allow on all sites to get access to coupons',
  iosOnboardingFlowSubtitle: '{{step, number}}/{{numberOfSteps, number}} {{text}}',
  extension: 'extension',
  howToEnable: 'How to enable',
  howToGetCoupons: 'How to get coupons',
  sweOnboardingStep1_1: 'Tap the <1>A</1><2>A</2> in the URL bar',
  sweOnboardingStepAlt1_1: 'Tap the aA in the URL bar',
  sweOnboardingStep1_2: 'Select “Manage Extensions”',
  sweOnboardingStep1_3: 'Toggle on',
  sweOnboardingStep2_1: 'Tap the <1>A</1><2>A</2> in the URL bar again',
  sweOnboardingStep2_2: 'Tap the',
  sweOnboardingStep2_3: 'Select “Always allow”',
  sweOnboardingStep2_4: 'Select “Always allow on every website”',

  sweOnboardingStep2_5_1: 'Go back to the',
  sweOnboardingStep2_5_2: 'Karma app',
  shopSmarter: 'Shop smarter with\nthe power of AI',
  joinOurUsers: 'Join our {{number, number}} million users\nwho are shopping\nsmarter from all their\ndevices',
  browseAndBuy:
    'Browse and buy from our desktop extension or\nuse the Karma app to ensure you’re always\ngetting the most out of your purchase.',
  joinCard1: '<0>{{value}}M+</0><1>shoppers</1>',
  joinCard2: '<0>15K</0><1>5 star reviews</1>',
  joinCard3: '<0>$5 cash</0><1>at sign-up</1>',
  joinCard4: '<0>2.3 million</0><1>mobile app users</1>',
  joinCard5: '<0>$7.50</0><1>avg order savings</1>',
  joinCard6: '<0>50 million</0><1>items tracked</1>',
  whatMakesKarmaGood: 'What makes Karma\nso good?',
  karmaGoodTitle1: 'Karma let’s you shop\nsmarter from anywhere',
  karmaGoodDescription1:
    'Karma’s browser extension meets you\nwherever you shop. Shop directly in the Karma\napp or share the items you want to Karma.',
  karmaGoodTitle2: 'Helps you plan your\nnext purchases',
  karmaGoodDescription2:
    'Not ready to buy just yet? Save it for later in the\nKarma app or share it to Karma and shop more\nmindfully. You can keep an eye on all your\nsaved items in one place.',
  karmaGoodTitle3: 'Finds and compares the\nbest deals',
  karmaGoodDescription3: 'Karma helps you shop smarter by instantly\ncomparing products from different retailers.',
  karmaGoodTitle4: 'So you can buy at the\nright time',
  karmaGoodDescription4:
    "Karma uses clever technology to track if the\nprice drops, or when it's back in stock, so you\ncan buy at the right time.",
  onlyOnMobile: 'Only on the mobile app',
  makeYourInspo: 'Make your inspo\na reality',
  easilyBringYourFavorite:
    'Easily bring your favorite social media looks\ninto your life, just share the posts to Karma and\nour AI will scan the image and find the items\nthat match.',
  empowerTouToMake: 'Empower you to\nmake the right\nchoices at the right\ntime when you shop\nonline.',
  dontTakeOurWord: 'Don’t take our word for it',
  totalSavings: 'Total savings',
  joinOurUsersDesktop: 'Join our {{number, number}} million users who are\nshopping smarter from all their devices',
  andAutomaticallyFindsYouCouponsAtCheckout: 'And automatically finds you coupons at checkout',
  clickAlwaysAllowOnEveryWebsite: 'Click <2>“Always Allow on Every Website“</2> so we can work on all your fave sites',
  weNeedYourPermissionToAutomaticallyShowYouTheBestDeals:
    'We need your permission to automatically show you the best deals on all your favorite shopping websites.',
  yourPrivacyAndSecurityIsImportantToUs: 'Your privacy and security are important to us - it’s in our',
  whileKarmaWillStillWork: 'While Karma will still work, it’ll require more effort on your part to find deals.',
  unfortunatelyWithoutTheAlwaysAllowPermission:
    'Unfortunately, without the “Always Allow on Every Website” permission you’ll have to manually click Karma whenever you shop',
  noThanksSignUpForAnAccount: 'No thanks, Sign up for an account',
  logInWithEmail: 'Log in with email',
  signUpWithEmail: 'Sign up with email',
  iAgreeToTermsOfUseAndPrivacyPolicy: 'I agree to <1>Terms of Use</1> and <3>Privacy Policy</3>',
  joinOurEnergeticTeam: 'Join Our Energetic Team',
  atKarmaWeReNotJustAboutWork:
    'At Karma, we’re not just about work; we’re about creating an environment where everyone’s input is valued and where each day brings a new opportunity for growth and impact.',
  beAPartOfTheJourney: 'Be a part of the journey',
  noPositionsAvailable: 'No positions available',
  apply: 'Apply',
  dontSeeAnythingThatFitsYourSkillSet:
    "Don't see anything that fits your skill set? Don't let that stop you from reaching out! If you think you can help us grow, send an email to",
  ifYouReReadyToMakeAnImpact:
    'If you’re ready to make an impact and thrive in a setting that values speed, fun, and collaboration, Karma might be your next great adventure.',
  exploreOurCareerOpportunities:
    'Explore our career opportunities and find out how you can contribute to our exciting journey ahead. Join us, and let’s achieve greatness together!',
  welcomeToKarma:
    'Welcome to Karma—where technology meets savvy shopping. Join us in our mission to revolutionize the way you shop, ensuring every purchase is just right for you.',
  empoweringSmartShopping: 'Empowering Smart Shopping:\nThe Karma Story',
  ourMissionPart1:
    'At Karma, we believe in empowering shoppers to make wise and informed purchasing decisions. Founded in 2015, our mission has always been to guide consumers toward smarter, more responsible shopping. As both a browser extension and mobile app, Karma harnesses the power of advanced AI to enhance your shopping experience. This technology predicts the optimal time to make a purchase, ensuring you get the best deal possible.',
  ourMissionPart2:
    'Over the years, Karma has continuously evolved, integrating cutting-edge technology to keep pace with the dynamic e-commerce landscape. We take pride in helping our users avoid buyer’s remorse and overspending by providing them with the tools they need to shop confidently!',
  hearFromOurUsers: 'Hear from our users',
  karmaPatents: 'Karma patents',
  systemAndMethodForPreauthorizing: 'System and method for preauthorizing database transaction commits',
  systemAndMethodForScraping: 'System and method for near real time web scraping',
  couponsHandled: 'Coupons? Handled',
  skipCouponHunt: 'Skip the coupon hunt. We’ll automatically apply the best discounts for you at checkout.',
  smartAlerts: 'Smart alerts for your wishlist',
  saveItemsGetAlerted: 'Save items and get alerted when prices drop or items are back in stock.',
  cashbackMadeSimple: 'Cashback made simple',
  earnMoneyBack: 'Earn money back on your purchases, turning shopping into savings.',
  startYourShoppingJourney: 'Start your shopping journey',
  searchForItemBrandStore: 'Search for an item, brand or store',
  quillDisclosure: 'HP, Epson, & Samsung excluded',
  theKButtonIsInMyWay: 'The K button is in my way',
  itWasntHelpful: 'It wasn’t helpful',
  reinstallTheExtension: 'Reinstall the extension',
  tooManyCouponPopupsAtCheckout: 'Too many coupon pop-ups at checkout',
  didYouKnowYouCanHideTheKButtonAndDisablePopupsFromYourSettings:
    'Did you know you can <1>hide</1> the K button and <3>disable</3> pop-ups from your settings?',
};
