import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  onboardingState: {},
  loaded: false,
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    successGetOnboardingState: (state, action) => {
      return {
        ...state,
        onboardingState: action.payload,
        loaded: true,
      };
    },
  },
});

export default onboardingSlice.reducer;
