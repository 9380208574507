import { call, put, select } from 'redux-saga/effects';
import getBrowser from 'functions/getBrowser';
import { handleServerError } from 'store/alerts/actions';
import * as userSelectors from 'store/user/selectors';
import { ITEMS } from 'consts/localStorage';
import { pushBackCongratsGuestModal, pushBackCongratulateCompleteSignUpModal } from 'store/modals/actions';
import { getQueryParam } from 'functions/urlHelpers';
import { QUERY_PARAMS } from 'consts/queryParams';
import { MODAL_TYPES } from 'consts/modals';
import * as onboardingApi from '../api';
import * as onboardingActions from '../actions';

const checkIsExtensionInstalledInCurrentBrowser = data => {
  return data.extension_installed.includes(getBrowser());
};

function* getOnboardingState() {
  try {
    const { data } = yield call(onboardingApi.getOnboardingState);
    const guest = yield select(userSelectors.getGuest);

    const completeSignUpModalDelay = localStorage.getItem(ITEMS.completeSignUpModalDelay);
    const congratulateSignupModalShown = localStorage.getItem(ITEMS.congratulateSignupModal);

    const modalsQueryParam = getQueryParam(QUERY_PARAMS.modals);
    const onboardingModal = getQueryParam(QUERY_PARAMS.onboardingModal);

    const isFromCompleteSignUp =
      typeof modalsQueryParam === 'string' && modalsQueryParam.includes(MODAL_TYPES.completeSignUp);
    const isCompleteSignUpModalTimestampInvalid = !completeSignUpModalDelay || Date.now() > completeSignUpModalDelay;
    const { tags_count } = data;

    yield put(onboardingActions.successGetOnboardingState(data));

    if (onboardingModal || !guest || isFromCompleteSignUp || !isCompleteSignUpModalTimestampInvalid) {
      return;
    }

    if (tags_count > 0 && checkIsExtensionInstalledInCurrentBrowser(data)) {
      yield put(pushBackCongratulateCompleteSignUpModal());

      return;
    }

    if (tags_count > 0 && !congratulateSignupModalShown) {
      yield put(pushBackCongratsGuestModal());
    }
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default getOnboardingState;
